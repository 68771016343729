import {
  BaseTextFieldProps,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import React from "react";
import { FieldRenderProps } from "react-final-form";

interface IProps
  extends FieldRenderProps<boolean, HTMLElement>,
    BaseTextFieldProps {
  label: string;
}

const CheckboxUtil: React.FC<IProps> = ({ input, label, name }) => {
  input.type = "checkbox";
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.checked}
          onChange={(event) => {
            input.onChange(event.target.checked);
          }}
          name={name}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default CheckboxUtil;

import { IBaseCart } from "../Models/cart";
import { toast } from "react-toastify";
import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ICart,
  ILocalStorageCart,
  IAttributeProductCart,
  IAddNoteToCart,
} from "../Models/cart";

export const slice = createGenericSlice({
  name: "orderEntryCart",
  initialState: {
    status: "loading",
    data: cartListGet("orderEntryCartList"),
  } as GenericState<ICart[]>,
  reducers: {
    emptyCart: (state) => {
      state.data = [];
      window.localStorage.removeItem("orderEntryCartList");
      state.status = "finished";
    },
    addNote: (state, action: PayloadAction<IAddNoteToCart>) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.id &&
          i.duplicateNum === action.payload.duplicateNum
      );
      if (existItem) {
        state.data = list.map((m) =>
          m.id === action.payload.id &&
          m.duplicateNum === action.payload.duplicateNum
            ? { ...m, note: action.payload.note }
            : m
        );
      }
      cartListSet("orderEntryCartList", state.data ? state.data : [], 24 * 60);
    },
    addToCart: (state, action: PayloadAction<ICart>) => {
      const list = state.data ? [...state.data] : [];
      const existItems = list.filter((i) => i.id === action.payload.id);
      if (existItems.length > 0) {
        const lastExistItem = existItems.sort(
          (a, b) => a.duplicateNum - b.duplicateNum
        )[existItems.length - 1];

        if (!lastExistItem.editable) {
          addMoreToCart({
            duplicateNum: 1,
            id: action.payload.id,
            quantity: 0,
          });
        } else {
          action.payload.duplicateNum = lastExistItem.duplicateNum + 1;
          state.data = [...list, { ...action.payload }];
          cartListSet("orderEntryCartList", state.data, 24 * 60);
        }
      } else {
        action.payload.duplicateNum = 1;
        discountValue(action.payload);
        state.data = [...list, { ...action.payload }];
        cartListSet("orderEntryCartList", state.data, 24 * 60);
      }
      //  else if (existItem) {
      //   state.data = list.map((m) =>
      //     m.id === action.payload.id &&
      //     m.duplicateNum === action.payload.duplicateNum
      //       ? { ...m, quantity: m.quantity + action.payload.quantity }
      //       : m
      //   );
      //   cartListSet("orderEntryCartList", state.data, 24 * 60);
      // }
      toast.success(`${action.payload.name} ${action.payload.nameForCart}`);
    },
    addMoreToCart: (state, action: PayloadAction<IBaseCart>) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.id &&
          i.duplicateNum === action.payload.duplicateNum
      );
      if (existItem) {
        existItem.quantity = existItem.quantity + action.payload.quantity;
        state.data = list.map((m) =>
          m.id === action.payload.id &&
          m.duplicateNum === action.payload.duplicateNum
            ? { ...discountValue(existItem) }
            : m
        );
        cartListSet("orderEntryCartList", state.data, 24 * 60);
      }
    },
    addAttributeToProductCart: (
      state,
      action: PayloadAction<IAttributeProductCart>
    ) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.productId &&
          i.duplicateNum === action.payload.duplicateNum
      );
      if (existItem) {
        list.map((m) =>
          m.id === action.payload.productId &&
          m.duplicateNum === action.payload.duplicateNum
            ? {
                ...m,
                attributes: m.attributes.push({
                  id: action.payload.id,
                  name: action.payload.name,
                  price: action.payload.price,
                  optionId: action.payload.optionId,
                  displayOrder: action.payload.displayOrder,
                }),
              }
            : m
        );
      }
      cartListSet("orderEntryCartList", state.data ? state.data : [], 24 * 60);
    },
    reduceAttributeFromProductCart: (
      state,
      action: PayloadAction<IAttributeProductCart>
    ) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.productId &&
          i.duplicateNum === action.payload.duplicateNum
      );
      if (existItem) {
        state.data = list.map((m) =>
          m.id === action.payload.productId &&
          m.duplicateNum === action.payload.duplicateNum
            ? {
                ...m,
                attributes: m.attributes.filter(
                  (f) => f.id !== action.payload.id
                ),
              }
            : m
        );
      }
      cartListSet("orderEntryCartList", state.data ? state.data : [], 24 * 60);
    },
    reduceFromCart: (state, action: PayloadAction<IBaseCart>) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.id &&
          i.duplicateNum === action.payload.duplicateNum
      );

      if (existItem) {
        if (existItem.quantity < 2) {
          const newList = list.filter(
            (m) =>
              m.id !== action.payload.id &&
              m.duplicateNum === action.payload.duplicateNum
          );
          state.data = newList;
        } else {
          existItem.quantity = existItem.quantity - action.payload.quantity;
          discountValue(existItem);

          state.data = list.map((m) =>
            m.id === action.payload.id &&
            m.duplicateNum === action.payload.duplicateNum
              ? { ...existItem }
              : m
          );
        }
        cartListSet(
          "orderEntryCartList",
          state.data ? state.data : [],
          24 * 60
        );
      }
    },
    removeFromCart: (state, action: PayloadAction<IBaseCart>) => {
      const list = state.data ? [...state.data] : [];
      const existItem = list.find(
        (i) =>
          i.id === action.payload.id &&
          i.duplicateNum === action.payload.duplicateNum
      );

      if (existItem) {
        state.data = list.filter((m) => m !== existItem);
      }
      cartListSet("orderEntryCartList", state.data ? state.data : [], 24 * 60);
    },
  },
});

export const selectCart = (state: { orderEntryCart: { data: ICart[] } }) =>
  state.orderEntryCart.data;
export const productQuantity = (state: {
  orderEntryCart: { data: ICart[] };
}) => {
  return state.orderEntryCart.data
    .map((item) => item.quantity)
    .reduce((prev, curr) => prev + curr, 0);
};
export const totalPrice = (state: { orderEntryCart: { data: ICart[] } }) => {
  return state.orderEntryCart.data
    .map((item) =>
      item.allowOverallDiscount &&
      (item.discount === undefined || item.discount === 0)
        ? item.quantity *
          (item.price +
            item.attributes
              .map((attr) => attr.price)
              .reduce((prev, curr) => prev + curr, 0))
        : 0
    )
    .reduce((prev, curr) => prev + curr, 0);
};
export const totalForDiscount = (state: {
  orderEntryCart: { data: ICart[] };
}) => {
  return state.orderEntryCart.data
    .map((item) =>
      item.allowOverallDiscount
        ? item.allowExtraDiscount
          ? item.discount === undefined || item.discount === 0
            ? item.quantity *
              (item.price +
                item.attributes
                  .map((attr) => attr.price)
                  .reduce((prev, curr) => prev + curr, 0))
            : 0
          : item.discount === undefined || item.discount === 0
          ? item.quantity * item.price
          : 0
        : 0
    )
    .reduce((prev, curr) => prev + curr, 0);
};
export const {
  addToCart,
  addMoreToCart,
  reduceFromCart,
  removeFromCart,
  emptyCart,
  addAttributeToProductCart,
  reduceAttributeFromProductCart,
  addNote,
} = slice.actions;

export default slice.reducer;

function cartListGet(key: string): ICart[] {
  let stringValue = window.localStorage.getItem(key);
  if (stringValue !== null) {
    let value: ILocalStorageCart = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.cartList;
    } else {
      window.localStorage.removeItem(key);
    }
  }
  return [];
}
function cartListSet(key: string, value: ICart[], expirationInMin = 10) {
  window.localStorage.removeItem(key);
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  let newValue: ILocalStorageCart = {
    cartList: value,
    expirationDate: expirationDate,
  };
  window.localStorage.setItem(key, JSON.stringify(newValue));
}
function discountValue(cartItem: ICart) {
  let price = cartItem.price * cartItem.quantity;
  let discountOrderItem = 0;

  switch (cartItem.tierPrice.discountType) {
    case "BOGOF":
      // debugger;
      if (cartItem.quantity % 2 === 0) {
        price = price * 0.5;
        discountOrderItem = price;
      } else if (cartItem.quantity > 1) {
        price = cartItem.price * (cartItem.quantity + 1) * 0.5;
        discountOrderItem = cartItem.price * (cartItem.quantity - 1) * 0.5;
      }
      break;

    case "BSFPOS":
      if (cartItem.quantity >= cartItem.tierPrice.quantity) {
        const quantity = Math.floor(
          cartItem.quantity / cartItem.tierPrice.quantity
        );
        price = cartItem.price * cartItem.tierPrice.get * quantity;
        const remaining = cartItem.quantity % cartItem.tierPrice.quantity;
        price += remaining * cartItem.price;
        discountOrderItem = cartItem.price * cartItem.quantity - price;
      }
      break;

    case "BSFP":
      if (cartItem.quantity >= cartItem.tierPrice.quantity) {
        const quantity = Math.floor(
          cartItem.quantity / cartItem.tierPrice.quantity
        );
        price = cartItem.tierPrice.value * quantity;
        const remaining =
          cartItem.quantity - quantity * cartItem.tierPrice.quantity;
        price += remaining * cartItem.price;
        discountOrderItem = cartItem.price * cartItem.quantity - price;
      }
      break;

    case "BSGSFHP":
      if (cartItem.quantity >= cartItem.tierPrice.quantity) {
        var quantity = Math.floor(
          cartItem.quantity / cartItem.tierPrice.quantity
        );
        var halfPrice =
          quantity * cartItem.tierPrice.get * cartItem.price * 0.5;
        var fullPrice =
          quantity *
          (cartItem.tierPrice.quantity - cartItem.tierPrice.get) *
          cartItem.price;
        var remaining =
          (cartItem.quantity % cartItem.tierPrice.quantity) * cartItem.price;
        price = halfPrice + fullPrice + remaining;
        discountOrderItem = cartItem.price * cartItem.quantity - price;
      }
      break;

    case "Reduced":
      price = cartItem.tierPrice.value * cartItem.quantity;
      discountOrderItem = cartItem.price * cartItem.quantity - price;
      break;

    case "Percentage":
      price = price - price * (cartItem.tierPrice.value / 100);
      discountOrderItem = cartItem.price * cartItem.quantity - price;
      break;
  }

  cartItem.total = price;
  cartItem.discount = discountOrderItem;

  return cartItem;
}

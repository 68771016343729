import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Button,
  createTheme,
  Grid,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
import JalaliUtils from "@date-io/jalaali";
import MomentUtils from "@date-io/moment";
import { FORM_ERROR } from "final-form";

import SubmitBooking from "./SubmitBooking/SubmitBooking";
import TimePicker from "../Common/TimePicker/TimePicker";
import { IModifyBookingTable } from "../../Models/tables";
import agent from "../../Services/agent";
import { history } from "../..";
import { IOpenTimes } from "../../Models/hangFire";
import Loading from "../Loading/Loading";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { selectInformation } from "../../Stores/informationSlice";

import { CheckBookingStyles } from "./CheckBookingStyles";
import DatePicker from "../Common/DatePicker/DatePicker";

const CheckBooking: React.FC = () => {
  const { t } = useTranslation();
  const classes = CheckBookingStyles();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [person, setPerson] = useState<number>(1);
  const [showChecked, setShowChecked] = useState(false);
  const [timePickerDisable, setTimePickerDisable] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [bookedCode, setBookedCode] = useState<any>();
  const [note, setNote] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [startTimeToday, setStartTimeToday] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [openTimes, setOpenTimes] = useState<IOpenTimes[]>([]);
  const information = useSelector(selectInformation);

  const theme = createTheme({
    direction: information.lang === "Persian" ? "rtl" : "ltr",
    typography: {
      fontFamily: information.lang === "Persian" ? "Samim" : "Arial",
    },
    palette: {
      type: information.darkTheme ? "dark" : "light",
      // Main
      primary: {
        main: information.mainColor || "#284919",
      },
      // Accent
      secondary: {
        main: information.accentColor || "#FC7D36",
      },
      // Background
      bg: {
        main: information.backGroundColor
          ? information.backGroundColor
          : "#E1CA9E",
      },
      // Header
      header: {
        main: information.headerColor || "#c00",
      },
      // Text
      title: {
        main: information.textColor || "#fff",
      },
      background: {
        paper: information.darkTheme ? "#424242" : "#ffffff",
      },
    },
    customPalette: {
      menuText: information.textMenuColor ?? "#000",
      menuBg: information.backGroundMenuColor ?? "#fff",
      fontHeaderColor: information.fontHeaderColor ?? "#fff",
    },
  });

  const getOpeningTimes = async () => {
    setLoading(true);
    await agent.Setting.getOpenTimes()
      .then((res) => {
        setOpenTimes(res);
        calcStartTime(res, new Date());
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const findTodayState = (v: IOpenTimes[], date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
    };
    const today = new Intl.DateTimeFormat("en-GB", options).format(date);
    const openTimesChecker = JSON.parse(JSON.stringify(v)) as IOpenTimes[];
    return openTimesChecker.find((a) => a.dayOfWeek === today);
  };

  const calcStartTime = (times: IOpenTimes[], selectedTime: Date) => {
    const todayState = findTodayState(times, selectedTime);
    const startTime = todayState?.items[0];

    const nowDate = new Date();

    const todayStartHours = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      startTime ? startTime.hour : 0,
      startTime ? startTime.minute : 0,
      0,
      0
    );
    const receiveTime = todayStartHours;
    setStartTime(receiveTime);
    const nowTime = new Date();
    let date: Date;
    if (receiveTime < nowTime) date = nowTime;
    else date = receiveTime;
    let minute = date.getMinutes();
    const hour = Math.floor(minute / 60);
    if (hour > 0) {
      date.setHours(date.getHours() + hour);
      minute = minute - hour * 60;
    }
    if (minute <= 15) {
      date.setMinutes(0);
    } else if (minute <= 30) {
      date.setMinutes(15);
    } else if (minute <= 45) {
      date.setMinutes(30);
    } else {
      date.setHours(date.getHours() + 1);
      date.setMinutes(0);
    }
    setSelectedTime(date);
    setStartTimeToday(date);

    const endTime = todayState?.items.pop();
    const todayEndHours = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      endTime ? endTime.hour : 0,
      endTime ? endTime.minute : 0,
      0,
      0
    );
    if (todayEndHours < date) {
      new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate() + 1,
        endTime ? endTime.hour : 0,
        endTime ? endTime.minute : 0,
        0,
        0
      );
    }
    setEndTime(todayEndHours);
  };

  const handleSelectedDate = (date: Date) => {
    setSelectedDate(date);
    calcStartTime(openTimes, date);
  };

  const submitReserve = () => {
    const now = new Date();
    if (
      selectedTime.getTime() < now.getTime() &&
      selectedDate.getDate() <= now.getDate()
    ) {
      toast.error(
        t("youShouldSelectTimeBetween") +
          " " +
          moment(new Date().getTime()).format("HH:mm") +
          t("and") +
          " 22:30"
      );
      return false;
    } else {
      const finalDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTime.getHours(),
        selectedTime.getMinutes(),
        0,
        0
      );
      const data: IModifyBookingTable = {
        person: person,
        day: finalDate,
        start: finalDate,
        fullName: name,
        phoneNumber: phoneNumber,
        email: email,
        note: note,
      };
      setLoading(true);
      agent.BookingTables.modify(data)
        .then((res) => {
          setBookedCode(res);
          setModalOpen(true);
        })
        .catch((error) => {
          if (error.status === 409) {
            toast.error(t("thisTimeIsFullSelectAnotherTime"));
          } else {
            toast.error(t("somethingWentWrongTryAgain"));
          }
          return {
            [FORM_ERROR]: error,
          };
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getOpeningTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  return (
    <>
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid container justifyContent="center">
        <Paper className={classes.paper}>
          <Grid item xs={12} container className={classes.checking}>
            <Grid item xs={12} className={classes.describe}>
              <h5>{t("bookingTableInOurRestaurant")}</h5>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              className={classes.item + " " + classes.person}
            >
              <Grid item xs={12} lg={6} container justifyContent="center">
                <TextField
                  id="outlined-basic"
                  margin="dense"
                  size="small"
                  label={t("person")}
                  variant="outlined"
                  type="number"
                  onChange={(event: any) => {
                    setPerson(event.target.value);
                  }}
                />
                {/* <Select
                  labelId="Number-of-Poeple"
                  id="Number-of-Poeple"
                  value={person}
                  onChange={handlePersonChange}
                  label={t("person")}
                  margin="dense"
                >
                  <MenuItem value={1}> 1 {t("person")}</MenuItem>
                  <MenuItem value={2}> 2 {t("person")}</MenuItem>
                  <MenuItem value={3}> 3 {t("person")}</MenuItem>
                  <MenuItem value={4}> 4 {t("person")}</MenuItem>
                  <MenuItem value={5}> 5 {t("person")}</MenuItem>
                  <MenuItem value={6}> 6 {t("person")}</MenuItem>
                  <MenuItem value={7}> 7 {t("person")}</MenuItem>
                  <MenuItem value={8}> 8 {t("person")}</MenuItem>
                </Select> */}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.item + " " + classes.date}
            >
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider
                  utils={
                    information.langDisplay === "fa-IR"
                      ? JalaliUtils
                      : MomentUtils
                  }
                  locale={information.langDisplay === "fa-IR" ? "fa" : "en-GB"}
                >
                  <DatePicker
                    noPast={true}
                    label={t("toDate")}
                    date={selectedDate}
                    setDate={handleSelectedDate}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              className={classes.item + " " + classes.time}
            >
              <TimePicker
                isDisable={timePickerDisable}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                minTime={
                  selectedDate.getDate() > new Date().getDate()
                    ? startTime
                    : startTimeToday
                }
                maxTime={endTime}
                timeFormat="HH:mm"
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              className={classes.item + " " + classes.btn}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowChecked(true);
                  setTimePickerDisable(true);
                }}
              >
                {t("checkTables")}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              className={classes.item + " " + classes.btn}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push("/");
                }}
              >
                {t("back")}
              </Button>
            </Grid>
          </Grid>

          {showChecked && (
            <SubmitBooking
              receivedDate={selectedDate}
              setShowChecked={setShowChecked}
              bookedCode={bookedCode}
              name={name}
              note={note}
              selectedTime={selectedTime}
              setEmail={setEmail}
              setName={setName}
              setNote={setNote}
              setPhoneNumber={setPhoneNumber}
              setSelectedTime={setSelectedTime}
              submitReserve={submitReserve}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              setTimePickerDisable={setTimePickerDisable}
              startTime={startTime}
              todayTime={startTimeToday}
              maxTime={endTime}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
};
export default CheckBooking;

import React, { useState, useEffect, Fragment, useCallback } from "react";
import "./AddressList.scss";
import { IAddress } from "../../Models/address";
import agent from "../../Services/agent";
import ModalContainer from "../Modal/ModalContainer";
import ModifyAddress from "./ModifyAddress";
import { Grid, Button, Card, CardContent, IconButton } from "@material-ui/core";
import { ProfileStyles } from "../../Style/ProfileStyles";
import { useTranslation } from "react-i18next";
import CustomModal from "../Modal/CustomModal";
import { toast } from "react-toastify";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import Loading from "../Loading/Loading";

interface IProps {
  userId?: string;
}

const AddressList: React.FC<IProps> = ({ userId }) => {
  const { t } = useTranslation();
  const classes = ProfileStyles();
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<any>(null);
  const [deleteAddModal, setDeleteAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressId, setAddressId] = useState("");

  const getAddresses = useCallback(async () => {
    await agent.Address.CustomerAddresses(userId).then((res) =>
      setAddresses(res)
    );
  }, [userId]);
  const handleModal = async (bool: boolean) => {
    setShowModal(bool);
    if (!bool) {
      await getAddresses();
    }
  };

  const handleRemoveAddress = (id: string) => {
    setDeleteAddModal(false);
    setLoading(true);
    agent.Address.delete(id)
      .then(() => {
        toast.success(t("removeSuccessfully"));
        getAddresses();
      })
      .catch(() => toast.error(t("somethingWentWrongTryAgain")));
    setLoading(false);
  };

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  if (loading) return <Loading />;
  return (
    <Fragment>
      {showModal && (
        <ModalContainer
          handleShow={handleModal}
          open={showModal}
          body={modalBody}
        />
      )}
      <CustomModal
        open={deleteAddModal}
        handleShow={() => setDeleteAddModal(false)}
        title={t("deleteAddress")}
        description={t("areYouSureToDeleteThisAddress")}
        action={
          <Fragment>
            <Button
              onClick={() => setDeleteAddModal(false)}
              variant="contained"
              color="primary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={() => handleRemoveAddress(addressId)}
              variant="contained"
              color="secondary"
            >
              {t("remove")}
            </Button>
          </Fragment>
        }
      />
      <Grid container className="profileAddresses">
        <Grid item xs={12}>
          <h3 className="profileTitle">{t("addresses")}</h3>
          <p className="profileDescribe">
            {t("fromHereYouWillBeAbleToSeeAllAddresses")}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.successButton}
            onClick={() => {
              setModalBody(
                <ModifyAddress userId={userId} handleModal={handleModal} />
              );
              setShowModal(true);
            }}
          >
            {t("addNewAddress")}
          </Button>
        </Grid>
        <Grid item xs={12} container className="addresses">
          {addresses.map((item) => (
            <Grid key={item.id} item xs={12} lg={6}>
              <Card className="addressCard">
                <CardContent>
                  <div className="cardHeader">
                    {item.fullName}
                    <div>
                      <IconButton
                        className={classes.errorButton}
                        onClick={() => {
                          setAddressId(item.id);
                          setDeleteAddModal(true);
                        }}
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                      <IconButton
                        className={classes.successButton}
                        onClick={() => {
                          setModalBody(
                            <ModifyAddress
                              handleModal={handleModal}
                              id={item.id}
                            />
                          );
                          setShowModal(true);
                        }}
                      >
                        <EditRoundedIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div>
                    <p>
                      {item.street}
                      <br />
                      {item.area}
                      <br />
                      {item.town}
                      <br />
                      {item.phoneNumber}
                      <br />
                      {item.zipPostalCode}
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddressList;

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const OrderStatusStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderStatus: {
      boxShadow: "unset",
      padding: "4rem 2rem",
      textAlign: "center",
      fontSize: "1.2rem",
      "& .success": {
        fontWeight: 600,
        "& h3": {
          textAlign: "center",
          color: "Green",
        },
        "& h1, h6": {
          textAlign: "center",
          color: theme.palette.text.primary,
        },
      },
      "& .wrong": {
        [theme.breakpoints.up("lg")]: {
          margin: "0 auto",
          width: "60%",
        },

        "& p": {
          textAlign: "center",
        },

        "& .desc": {
          marginBottom: 5,
          fontWeight: 600,
          color: theme.palette.secondary.main,
        },
        "& .errorMessage": {
          color: theme.palette.text.primary,
          fontSize: ".9rem",
        },
      },

      "& button": {
        margin: 20,
      },
    },
  })
);

import React, { useCallback, useEffect, useState } from "react";
import Cart from "../components/RedisCart/Cart";
import { Switch, Route, Redirect } from "react-router-dom";
import { Hidden, Grid } from "@material-ui/core";
import OrderStatus from "../components/OrderStatus/OrderStatus";
import MainProduct2 from "../components/Design2/Product2/MainProduct2/MainProduct2";
import ProductList2 from "../components/Design2/Product2/ProductList2/ProductList2";
import { Ordering3Styles } from "./Styles/Ordering3Styles";
import { useDispatch, useSelector } from "react-redux";
import { removeCategoryId } from "../Stores/commonSlice";
import DeliveryCollectionModal from "../components/DeliveryCollectionModal/DeliveryCollectionModal";
import { ICategory } from "../Models/category";
import agent from "../Services/agent";
import CategoryMenu2 from "../components/Design2/CategoryMenu2/CategoryMenu2";
import { selectInformation } from "../Stores/informationSlice";
import FreebiesProducts from "../components/Design2/FreebiesProducts/FreebiesProducts";
import { selectCart } from "../Stores/redisCartSlice";
import { selectDelivery } from "../Stores/deliverySlice";

const Ordering3 = (props: any) => {
  const dispatch = useDispatch();
  const information = useSelector(selectInformation);
  const { location } = props;
  const [openModal, setOpenModal] = useState<boolean>(
    (information.openOrder || information.preOrder) &&
      (information.minimumDeliveryTime > 0 ||
        information.minimumCollectionTime > 0) &&
      location.pathname === "/ordering/departments"
  );
  const classes = Ordering3Styles();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const cartList = useSelector(selectCart);

  const getMenuCategories = useCallback(async () => {
    await agent.Categories.homePageCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  useEffect(() => {
    getMenuCategories();

    if (location.pathname === "/ordering/departments") {
      dispatch(removeCategoryId());
    }
  }, [dispatch, getMenuCategories, location.pathname]);
  return (
    <Grid container>
      <DeliveryCollectionModal
        preOrder={information.preOrder}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />

      <Hidden xsDown>
        <Grid item sm={3} md={2} lg={2} xl={2} className={classes.padding}>
          <div className={classes.sticky}>
            <CategoryMenu2 categories={categories} />
          </div>
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={9} md={10} lg={7} xl={7}>
        <div
          style={{
            height:
              categories.length <= 15
                ? "90vh"
                : categories.length * 34 + 45 + 30,
          }}
          className={classes.scrollMain}
        >
          <div className="main mainMargin">
            <Switch>
              {information.allowFreebies && cartList.userCart.showFreebie ? (
                <Route
                  path="/ordering/departments/freebies-products"
                  component={FreebiesProducts}
                />
              ) : (
                <Route path="/ordering/departments/freebies-products">
                  <Redirect to="/ordering/departments/" />
                </Route>
              )}
              <Route
                path="/ordering/options/:categoryId/:mainProductId/:productId?/:duplicateNum?"
                component={MainProduct2}
              />
              <Route
                path="/ordering/departments/:categoryId"
                component={ProductList2}
              />
              {/* <Route
                path="/ordering/departments"
                component={CategoryChildList2}
              /> */}
              {categories[0]?.id && (
                <Route>
                  <Redirect to={`/ordering/departments/${categories[0]?.id}`} />
                </Route>
              )}
              <Route
                path="/ordering/order-status/:orderId/:status/:paymentSenseId?/:delTime?/:note?"
                component={OrderStatus}
              />
            </Switch>
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={3}
        xl={3}
        className={classes.padding}
      >
        <div className={classes.sticky}>
          <Cart setDeliveryModal={setOpenModal} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Ordering3;

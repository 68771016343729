import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Grid, IconButton } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { IMainProduct } from "../../../../../Models/mainProduct";
import {
  selectInformation,
  selectMoney,
} from "../../../../../Stores/informationSlice";
import { selectDelivery } from "../../../../../Stores/deliverySlice";
import { addToCart, selectCart } from "../../../../../Stores/redisCartSlice";
import { history } from "../../../../..";
import { ITierPrice } from "../../../../../Models/tierPrices";
import { ICartRequest } from "../../../../../Models/Cart/ICartRequest";
import { ICart } from "../../../../../Models/Cart/ICart";
import { GetStoreId } from "../../../../../Services/storeSession";
import agent from "../../../../../Services/agent";
import ChildLoading from "../../../../Loading/ChildLoading";

import { ProductItemsStyles } from "./ProductItemsStyles";

interface IProps {
  mainProduct: IMainProduct;
}

const ProductItemWithRedisCart: React.FC<IProps> = ({ mainProduct }) => {
  const classes = ProductItemsStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const money = useSelector(selectMoney);
  const delivery = useSelector(selectDelivery);
  const information = useSelector(selectInformation);

  // =======================useEffect=========================
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;

  // =========================methods=========================
  const checkDiscount = (item: ITierPrice) => {
    // Check Offers Date & Offer is enable
    const startDate = item.startDateTime;
    const endDate = item.endDateTime;
    const today = new Date();
    if (
      startDate &&
      endDate &&
      today > new Date(startDate) &&
      today < new Date(endDate) &&
      information.allowOffers
    )
      return true;
    else return false;
  };

  const handleAddToCart = async () => {
    if (information.openOrder || information.preOrder) {
      if (storeId) {
        let requestCart: ICartRequest = {
          id: cartId,
          storeId: storeId,
          delivery: delivery?.delivery,
          isFreebie: false,
          productId: mainProduct.defaultProductId,
          duplicateNum: 0,
          quantity: 1,
          note: "",
          editable: mainProduct.requiredOptions,
          postCode: delivery?.postCode,
          attributes: [],
        };
        setLoading(true);
        await agent.Cart.post(requestCart)
          .then((res: ICart) => {
            if (res) {
              dispatch(addToCart(res));
            } else {
              toast.error(t("somethingWentWrongTryAgain"));
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => setLoading(false));
      }
    } else toast.warn(t("onlineOrderClosed"));
  };

  if (loading) return <ChildLoading />;
  // ==========================tsx============================
  return (
    <Grid container className={classes.productItems2}>
      <Grid item xs={12} md={9} className="naming">
        <span className="name">{mainProduct.name}</span>
        <div className="desc">
          <ShowMoreText
            lines={1}
            more={
              <IconButton color="primary">
                <ExpandMoreRoundedIcon />
              </IconButton>
            }
            less={
              <IconButton color="secondary">
                <ExpandLessRoundedIcon />
              </IconButton>
            }
            className="contentStyle"
            anchorClass="anchorStyle"
            expanded={false}
            width={0}
          >
            <div
              dangerouslySetInnerHTML={{ __html: mainProduct.description }}
            />
          </ShowMoreText>
        </div>
        <div className={classes.discounts}>
          <span className={classes.discount}>
            {mainProduct.products.length > 0 &&
              mainProduct.products
                .find((item) => item.tierPrices?.length > 0)
                ?.tierPrices.filter((item) => checkDiscount(item))[0]?.title}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={3} className="ordering">
        <span className="price">
          {mainProduct.defaultProductPrice > 0 &&
            money(mainProduct.defaultProductPrice)}
        </span>
        {!mainProduct.requiredOptions ? (
          <IconButton
            size="small"
            onClick={handleAddToCart}
            className={classes.addToCartBtn}
          >
            {/* Add To Cart */}
            <div className="whiteBg" />
            <AddCircleRoundedIcon fontSize="large" />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => {
              history.push(
                `/ordering/options/${mainProduct.categoryId}/${mainProduct.id}`
              );
            }}
            className={classes.optionBtn}
          >
            {/* Required Options */}
            <div className="whiteBg" />
            <AddCircleRoundedIcon fontSize="large" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductItemWithRedisCart;

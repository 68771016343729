import { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Button,
  IconButton,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { DeliveryAddressStyles } from "./DeliveryAddressStyles";
import { IAddress } from "../../Models/address";
import agent from "../../Services/agent";
import ModifyAddress from "../Address/ModifyAddress";
import Loading from "../Loading/Loading";
import { selectCart } from "../../Stores/redisCartSlice";
import { history } from "../..";
import { selectInformation } from "../../Stores/informationSlice";
import { selectDelivery } from "../../Stores/deliverySlice";
import CustomModal from "../Modal/CustomModal";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { RegisterType } from "../../Models/order";

const DeliveryAddress = () => {
  const { t } = useTranslation();
  const cartList = useSelector(selectCart);
  const information = useSelector(selectInformation);
  const delivery = useSelector(selectDelivery);

  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [address, setAddress] = useState<IAddress>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleModal = async (bool: boolean) => {
    setModal(bool);
    if (!bool) {
      await getAddress();
    }
  };

  const getAddress = useCallback(() => {
    agent.Address.CustomerAddresses()
      .then((res) => {
        const filtered = res
          .filter((a) => a.zipPostalCode != null && a.zipPostalCode !== "")
          .filter(
            (item) =>
              item.zipPostalCode.replace(/\s+/g, "").toLowerCase() ===
              delivery.postCode.replace(/\s+/g, "").toLowerCase()
          );
        setAddresses(filtered);
        if (filtered.length > 0) setAddress(filtered[0]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [delivery]);

  const handleChange = (address: IAddress) => {
    setAddress(address);
  };

  const handleCheckOut = async () => {
    if (address === undefined) {
      toast.warn(t("pleaseSelectAnAddress"));
    } else {
      setLoading(true);

      if (cartList.userCart.carts.length > 0 && address !== undefined) {
        await agent.DeliveryCharges.value(address.zipPostalCode)
          .then((res) => {
            if (!res.canDelivery) {
              toast.warn(t("youJustCanCollectionYourOrder"));
            }
          })
          .catch((error) => {
            toast.error(
              t("yourPostCodeIsWrongAndYouJustCanCollectionYourOrder")
            );
          });
        await agent.Order.create({
          cartId: cartList.userCart.id,
          orderType: RegisterType.Web,
        })
          .then((res) => {
            history.push(`/payment/${res}`);
            setLoading(false);
          })
          .catch((error) => {
            toast.error(t("somethingWentWrongTryAgain"));
            setLoading(false);
          });
      } else {
        setLoading(false);
        toast.error("create an address plz");
        return false;
      }
    }
  };

  const classes = DeliveryAddressStyles();

  useEffect(() => {
    getAddress();
    window.scrollTo(0, 0);
  }, [getAddress]);

  if (loading) return <Loading />;
  return (
    <Fragment>
      {modal && (
        <CustomModal
          action={null}
          handleShow={handleModal}
          open={modal}
          title=""
          body={
            <ModifyAddress
              postCode={delivery.postCode}
              handleModal={handleModal}
            />
          }
        />
      )}
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid container className={classes.checkout}>
        <Grid item xs={12} sm={5} className={classes.addressAction}>
          <h5>{t("selectYourAddress")}</h5>
          <IconButton
            color="primary"
            aria-label={t("addNew")}
            onClick={() => setModal(true)}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.checkoutBtns}>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            size="large"
          >
            {t("back")}
          </Button>
          <Button
            onClick={() => history.push("/ordering/departments")}
            variant="contained"
            color="primary"
            size="large"
          >
            {t("backToShop")}
          </Button>
          <Button
            onClick={() => handleCheckOut()}
            variant="contained"
            color="secondary"
            size="large"
          >
            {t("payment")}
          </Button>
        </Grid>

        {addresses
          .filter(
            (item) =>
              item.zipPostalCode.replace(/\s+/g, "").toLowerCase() ===
              delivery.postCode.replace(/\s+/g, "").toLowerCase()
          )
          .map((item: IAddress) => (
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              key={item.id}
              className={classes.addressContainer}
            >
              <Card>
                <CardContent>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value={item.fullName}
                      control={<Radio color="primary" />}
                      label={item.fullName}
                      checked={address === item}
                      onChange={() => handleChange(item)}
                      classes={{ root: classes.addressLabel }}
                    />
                  </FormControl>
                  <hr />
                  <div className={classes.address}>
                    <p>
                      <span>{t("street")}:</span> {item.street}
                    </p>
                    <p>
                      <span>{t("area")}:</span> {item.area}
                    </p>
                    <p>
                      <span>{t("town")}:</span> {item.town}
                    </p>
                    <p>
                      <span>{t("postCode")}:</span> {item.zipPostalCode}
                    </p>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Fragment>
  );
};

export default DeliveryAddress;

import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Button, ButtonGroup } from "@material-ui/core";

import CustomModal from "../Modal/CustomModal";
import { CounterMode } from "../../Models/Enums/counterMode";

import { ProductCounterStyles } from "./ProductCounterStyles";

interface IProps {
  quantity: number;
  handleUpdateCart(quantity: number, counterMode?: CounterMode): void;
}

const ProductCounter: React.FC<IProps> = ({ quantity, handleUpdateCart }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const classes = ProductCounterStyles();

  return (
    <Fragment>
      <CustomModal
        title={t("deleteProduct")}
        description={t("thisWillDeleteSelectedProduct")}
        open={modal}
        handleShow={() => setModal(false)}
        action={
          <Fragment>
            <Button
              onClick={() => setModal(false)}
              variant="contained"
              color="secondary"
            >
              {t("no")}
            </Button>
            <Button
              onClick={() => {
                handleUpdateCart(0);
                setModal(false);
              }}
              variant="contained"
              color="primary"
            >
              {t("yes")}
            </Button>
          </Fragment>
        }
      />

      <ButtonGroup size="small" className={classes.buttonGroup}>
        <Button
          aria-label="Increment value"
          onClick={() => {
            if (quantity > 1) {
              handleUpdateCart(quantity - 1);
            } else if (quantity === 1) {
              setModal(true);
            }
          }}
        >
          <RemoveIcon />
        </Button>
        <Button disabled className={classes.qty}>
          {quantity}
        </Button>
        <Button
          aria-label="Decrement value"
          onClick={() => {
            handleUpdateCart(quantity + 1, CounterMode.Increase);
          }}
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
    </Fragment>
  );
};
export default ProductCounter;

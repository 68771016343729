import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { IAttributeCartRequest } from "../../../Models/Cart/IAttributeCartRequest";
import { IProductAttributeValue } from "../../../Models/productAttributeValue";
import { selectMoney } from "../../../Stores/informationSlice";

interface IProps {
  productAttributeValue: IProductAttributeValue;
  handleCheckBox: (
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    checkbox: boolean,
    attributeType: string,
    forAllSelectCount: number,
    allSelectId: string | undefined,
    limit: number,
    payForMore: boolean
  ) => boolean;
  attributeType: string;
  optionLimit: number;
  optionDisplayOrder: number;
  attributes: IAttributeCartRequest[];
  forAllSelectCount: number;
  allSelectId: string | undefined;
  payForMore: boolean;
}
const Checkbox1: React.FC<IProps> = ({
  productAttributeValue,
  handleCheckBox,
  optionLimit,
  optionDisplayOrder,
  attributeType,
  attributes,
  forAllSelectCount,
  allSelectId,
  payForMore,
}) => {
  const money = useSelector(selectMoney);
  return (
    <Fragment>
      <input
        onChange={(e) => {
          const res = handleCheckBox(
            productAttributeValue,
            optionDisplayOrder,
            e.target.checked,
            attributeType,
            forAllSelectCount,
            allSelectId,
            optionLimit,
            payForMore
          );
          if (!res) {
            e.target.checked = false;
          }
        }}
        type="checkbox"
        value={productAttributeValue.id}
        id={productAttributeValue.id.toString()}
        checked={
          attributes.find((m) => m.id === productAttributeValue.id) !==
          undefined
        }
      />
      <label htmlFor={productAttributeValue.id.toString()}>
        {productAttributeValue.name}&nbsp;
        {productAttributeValue.priceAdjustment > 0
          ? money(productAttributeValue.priceAdjustment)
          : ""}
      </label>
    </Fragment>
  );
};

export default Checkbox1;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
} from "@material-ui/core";

import { selectInformation } from "../../Stores/informationSlice";

interface IProps {
  body: any;
  handleShow: (show: boolean) => void;
  open: boolean;
  title?: string;
  dialog?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
}

const ModalContainer: React.FC<IProps> = ({
  body,
  handleShow,
  open,
  title,
  dialog = true,
  fullWidth,
  maxWidth
}) => {
  const scroll: DialogProps["scroll"] = "paper";
  const information = useSelector(selectInformation);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => handleShow(false)}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle
        style={{
          backgroundColor:
            information.darkTheme &&
              !window.location.pathname.startsWith("/panel")
              ? "#424242"
              : "#fff",
        }}
        id="scroll-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor:
            information.darkTheme &&
              !window.location.pathname.startsWith("/panel")
              ? "#424242"
              : "#fff",
        }}
        dividers={scroll === "paper"}
      >
        {body}
      </DialogContent>
      {dialog && (
        <DialogActions
          style={{
            backgroundColor:
              information.darkTheme &&
                !window.location.pathname.startsWith("/panel")
                ? "#424242"
                : "#fff",
          }}
        >
          <Button
            onClick={() => handleShow(false)}
            variant="contained"
            color="secondary"
          >
            {t("exit")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalContainer;

import { createValidator } from "revalidate";

export const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  "your email is not valid"
);
export const isGreaterThan = (n: number) =>
  createValidator(
    (message) => (value: number) => {
      if (value <= n) {
        return message;
      }
    },
    (field) => `${field} must be greater than ${n}`
  );
export const isBetween = (min: number, max: number) =>
  createValidator(
    (message) => (value) => {
      if (value && (Number(value) < min || Number(value) > max)) {
        return message;
      }
    },
    (field) => `${field} must be greater than ${min} and lower than ${max}`
  );

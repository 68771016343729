import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITierPrice } from "../Models/tierPrices";

export const slice = createGenericSlice({
  name: "discounts",
  initialState: {
    status: "loading",
    data: {},
  } as GenericState<ITierPrice[]>,
  reducers: {
    setDiscounts: (state, action: PayloadAction<ITierPrice[]>) => {
      state.status = "finished";
      state.data = action.payload;
    },
  },
});

export const selectDiscounts = (state: { discounts: { data: ITierPrice[] } }) =>
  state.discounts.data;

export const { setDiscounts } = slice.actions;

export default slice.reducer;

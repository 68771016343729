import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const GuestRegisterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      margin: "1rem",

      "& h4": {
        textAlign: "center",
      },
    },
    deliveryCollection: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& button": {
        fontWeight: 600,
      },
    },
  })
);

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const Navbar2Styles = makeStyles((theme: Theme) =>
  createStyles({
    navbar2: {
      width: "100vw",
      position: "relative",
      backgroundColor: theme.palette.header.main,
    },
    heading: {
      height: 40,
      background: "#00000010",
    },
    rtlFlex: {
      flexFlow: "row-reverse !important",
      justifyContent: "flex-start !important",
    },
    menu: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.customPalette.menuBg,

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        "& button": {
          margin: "0 0 1rem 2rem",
        },
      },
    },
    storeName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",

      "& a": {
        width: "80%",
        color: theme.palette.title.main,
        "&:hover": {
          opacity: 0.9,
        },
        "& h2": {
          margin: 0,
          lineHeight: 1.3,
          textAlign: "center",
        },
        "& h3": {
          margin: 0,
          // lineHeight: 1.3,
          textAlign: "center",
        },
      },
    },
    storeLogo: {
      "& img": {
        objectFit: "contain",
        width: 500,
        margin: "5px auto 0",
        display: "block",
        [theme.breakpoints.up("sm")]: {
          height: 120,
        },
        [theme.breakpoints.up("lg")]: {
          height: 150,
          top: 5,
        },
      },

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        "& img": {
          width: 270,
          height: 150,
        },
      },
    },
    ratingImage: {
      width: "120px",

      "@media(min-width:960px)": {
        position: "absolute",
        bottom: "40px",
        left: "0",
        width: "150px",
      },
    },

    menuBtn: {
      "& button": {
        color: theme.customPalette.fontHeaderColor,
      },

      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: -5,
        left: 0,
      },
    },
    orderStatus2: {
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: 13,
        right: 10,
      },
    },
    orderNowBtn: {
      display: "flex",
      justifyContent: "center",
      zIndex: 3,
      borderRadius: 5,

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {},

      "& a": {
        padding: 7,
        backgroundColor: "#284919",
        color: "#ffffff !important",
        fontWeight: 600,
        borderRadius: 5,
        "& svg": {
          marginLeft: "0.5rem",
        },
      },
    },
    orderStatus: {
      "& span": {
        marginLeft: 5,
        padding: "5px 10px",
        backgroundColor: "#fff",
        fontWeight: 600,
        borderRadius: 5,
      },
      "& .open": {
        color: "green",
      },
      "& .close": {
        color: "red",
      },
    },
    cartBtn: {
      width: 100,
      fontWeight: 600,
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "space-around",
      },
    },
  })
);

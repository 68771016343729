import { Grid, Button, Paper } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { history } from "../../..";
import { selectInformation } from "../../../Stores/informationSlice";
import TemporaryShopClose from "../../TemporaryShopClose/TemporaryShopClose";
import Carousel from "../Carousel/Carousel";
import { LandingPageStyles } from "./LandingPageStyles";

const LandingPage = () => {
  const classes = LandingPageStyles();
  const { t } = useTranslation();
  const information = useSelector(selectInformation);

  return (
    <>
      {information.temporaryShopClose && (
        <TemporaryShopClose
          temporaryShopCloseText={information.temporaryShopCloseText}
        />
      )}
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          lg={10}
          container
          component={Paper}
          className={classes.root}
        >
          <Grid item xs={12} lg={4} className="info">
            {information.title !== null &&
            information.title.length > 0 &&
            information.title.startsWith("<") ? (
              <>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.title }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.description }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></div>
              </>
            ) : (
              <>
                <h2>{t("welcomeTo")}</h2>
                <h1>
                  {information.title ? information.title : information.name}
                </h1>
                <h4 className="services">{information.description}</h4>
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></p>
              </>
            )}

            {!information.openOrder && information.preOrder && (
              <p className={classes.preOrder}>
                Note:&nbsp;
                <span>Online ordering is closed</span>, You can submit your
                order as pre-order and get it when online ordering is opened.
              </p>
            )}
            <div className={classes.action}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => history.push("/ordering/departments")}
              >
                {!information.openOrder && information.preOrder
                  ? t("preOrdering")
                  : t("reserveTable")}
              </Button>
              {information.bookingTable && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => history.push("/table-reserve")}
                >
                  {t("reserveTable")}
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Carousel />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingPage;

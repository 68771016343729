import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const MenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu2: {
      display: "flex",
      alignItems: "center",
      padding: 5,
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    hoverMenu: {
      borderRight: `1px solid ${theme.palette.secondary.main}`,
      "&:last-child": {
        borderRight: "none",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        fontWeight: 600,
        color: `${theme.customPalette.menuText} !important`,
        cursor: "pointer",

        "&:hover": {
          color: theme.customPalette.menuText,
          filter: "brightness(0.5)",
          textDecoration: "none",
          opacity: 0.9,
        },
      },
    },
    link: {
      display: "block",
      color: theme.customPalette.menuText,

      "& li": {
        filter: "brightness(0.5)",
      },
      "&:hover": {
        color: theme.customPalette.menuText,
      },
      "&:first-child": {
        paddingTop: 5,
        borderRadius: "5px 5px 0 0",
        // borderTop: "1px solid #000",
      },
      "&:last-child": {
        paddingBottom: 5,
        borderRadius: "0 0 5px 5px",
        // borderBottom: "1px solid #000",
      },
    },
  })
);

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const ProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorButton: {
      color: theme.palette.secondary.main,
    },
    successButton: {
      color: theme.palette.primary.main,
    },
  })
);

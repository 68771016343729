import React from "react";
import { FieldRenderProps } from "react-final-form";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

interface IProps extends FieldRenderProps<string, HTMLElement> {
  placeholder: string;
  label: string;
  textFormat: string;
  disable?: boolean;
  fullWidth?: boolean;
  margin?: boolean;
}

const NumberFormatCustom: React.FC<IProps> = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
  textFormat,
  placeholder,
  textHelper,
  disable,
  fullWidth = true,
  margin,
}) => {
  return (
    <NumberFormat
      onValueChange={(values) => {
        const { value } = values;
        input.onChange(value);
      }}
      margin={margin ? "normal" : "dense"}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      allowEmptyFormatting
      mask="_"
      variant="outlined"
      helperText={textHelper}
      customInput={TextField}
      format={textFormat}
      decimalScale={2}
      fixedDecimalScale={true}
      {...input}
      type="text"
      onFocus={(e: any) => e.target.select()}
      disabled={disable}
    />
  );
};

export default NumberFormatCustom;

import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  Hidden,
} from "@material-ui/core";

import { selectInformation, selectMoney } from "../../Stores/informationSlice";
import OptionDropDown from "./DropDown/OptionDropDown";
import Radio1 from "./Radio1/Radio1";
import Checkbox1 from "./Checkbox1/Checkbox1";
import MultiSelectDropdown from "./MultiSelectDropDown/MultiSelectDropdown";
import Radio2 from "./Radio2/Radio2";
import { GetStoreId } from "../../Services/storeSession";
import agent from "../../Services/agent";
import { selectDelivery } from "../../Stores/deliverySlice";
import { addToCart, selectCart } from "../../Stores/redisCartSlice";
import { ISoloProduct } from "../../Models/product";
import { ITierPrice } from "../../Models/tierPrices";
import { ICartRequest } from "../../Models/Cart/ICartRequest";
import { ICart } from "../../Models/Cart/ICart";
import {
  IAttributeCartRequest,
  IproductAttributeValueType,
} from "../../Models/Cart/IAttributeCartRequest";
import { IAttributeCartResponse } from "../../Models/Cart/IAttributeCartRespons";
import { IProductAttributeValue } from "../../Models/productAttributeValue";
import { IProductAttributeMapping } from "../../Models/productAttributeMapping";
import ProductCounter from "../ProductCounter/ProductCounter";
import ModifyOrderNote from "../ModifyOrderNote/ModifyOrderNote";
import ChildLoading from "../Loading/ChildLoading";
import { history } from "../..";

import { OptionsStyles } from "./OptionsStyles";
import { Options2Styles } from "./Options2Styles";

interface IProps {
  product: ISoloProduct;
  categoryId: string;
  duplicateNum: number;
}

const Options: React.FC<IProps> = ({ product, categoryId, duplicateNum }) => {
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const information = useSelector(selectInformation);
  const delivery = useSelector(selectDelivery);
  const money = useSelector(selectMoney);

  // =========================values==========================
  const storeId = GetStoreId();
  const cart = useSelector(selectCart);
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;
  const classes =
    information.langDisplay === "en-GB" ? Options2Styles() : OptionsStyles();

  const productInCart = cart?.userCart?.carts?.find(
    (m) => m.productId === product.id && m.duplicateNum === duplicateNum
  );

  // ========================useState=========================
  const [note, setNote] = useState<string>(
    productInCart ? productInCart.note : ""
  );
  console.log(1);

  const checkDiscount = (item: ITierPrice) => {
    // Check Offers Date & Offer is enable
    const startDate = item.startDateTime;
    const endDate = item.endDateTime;
    const today = new Date();
    if (
      startDate &&
      endDate &&
      today > new Date(startDate) &&
      today < new Date(endDate) &&
      information.allowOffers
    )
      return true;
    else return false;
  };

  const discountTitle = () => {
    const list: string[] = [];
    if (product) {
      product.tierPrices.forEach(
        (discount) => checkDiscount(discount) && list.push(discount.title)
      );
    }
    return list;
  };
  const getAttributeCartRequestFromResponse = (
    responses: IAttributeCartResponse[]
  ): IAttributeCartRequest[] => {
    let requstes: IAttributeCartRequest[] = responses.map((attribute) => {
      return {
        id: attribute.id,
        displayOrder: attribute.displayOrder,
        quantity: attribute.quantity,
        attributeControlTypeId: attribute.attributeControlTypeId,
        productAttributeMappingId: attribute.productAttributeMappingId,
        type: attribute.type,
      };
    });
    return requstes;
  };
  const preSelectAttributes = () => {
    let list: IAttributeCartRequest[] = [];
    product.productAttributeMappings.map((mapping) => {
      mapping.productAttributeValues.map((value, index) => {
        if (value.isPreSelected) {
          list.push({
            attributeControlTypeId: mapping.attributeControlTypeId,
            displayOrder: index,
            id: value.id,
            productAttributeMappingId: mapping.id,
            quantity: 1,
            type: value.type,
          });
        }
      });
    });
    return list;
  };

  const [requestCart, setRequestCart] = useState<ICartRequest>({
    id: cartId,
    storeId: storeId ?? "",
    isFreebie: false,
    delivery: delivery?.delivery,
    productId: product.id,
    duplicateNum: productInCart ? productInCart.duplicateNum : 0,
    quantity: productInCart ? productInCart.quantity : 1,
    note: note,
    editable: productInCart ? productInCart.editable : true,
    postCode: delivery?.postCode,
    attributes: [],
  });

  const [attributes, setAttributes] = useState<IAttributeCartRequest[]>([]);

  const discountTitles = discountTitle();
  const [loading, setLoading] = useState(false);

  // =========================methods=========================

  const checkForcibleOneSelect = (
    productAttribues: IProductAttributeMapping[],
    cartAttributes: IAttributeCartRequest[]
  ): boolean => {
    if (
      checkForcibleByType(productAttribues, cartAttributes, "RadioList") &&
      checkForcibleByType(productAttribues, cartAttributes, "RadioList2") &&
      checkForcibleByType(productAttribues, cartAttributes, "DropdownList")
    )
      return true;
    return false;
  };

  const checkForcibleByType = (
    productAttribues: IProductAttributeMapping[],
    cartAttributes: IAttributeCartRequest[],
    type: string
  ): boolean => {
    let result = false;
    let attributeMappingsByType = productAttribues.filter(
      (item) => item.attributeControlTypeId === type
    );
    if (attributeMappingsByType && attributeMappingsByType.length > 0) {
      for (let i = 0; i < attributeMappingsByType.length; i++) {
        let attributeSeleceted = cartAttributes.filter(
          (item) =>
            item.productAttributeMappingId === attributeMappingsByType[i].id
        );
        if (attributeSeleceted && attributeSeleceted.length > 0) {
          result = true;
        } else {
          information.langDisplay === "en-GB"
            ? toast.error(
                `Please choose your ${attributeMappingsByType[i].productAttributeName}`
              )
            : toast.error(
                `لطفأ ${attributeMappingsByType[i].productAttributeName} تان را انتخاب کنید`
              );
          result = false;
          break;
        }
      }
    } else result = true;
    return result;
  };

  const handleCheckBox = (
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    checked: boolean,
    attributeType: string,
    forAllSelectCount: number,
    allSelectId: string | undefined,
    limit: number,
    payForMore: boolean
  ) => {
    if (checked) {
      handleAddAttributeToRequestCart(
        {
          id: value.id,
          displayOrder: optionDisplayOrder,
          quantity: 1,
          attributeControlTypeId: attributeType,
          productAttributeMappingId: value.productAttributeMappingId,
          type: value.type,
        },
        value.type === IproductAttributeValueType.SingleSelect ||
          value.type === IproductAttributeValueType.AllSelect,
        forAllSelectCount,
        allSelectId,
        limit,
        payForMore
      );
    } else {
      handleRemoveAttributeFromRequestCart(value.id);
    }
    return true;
  };

  const handleRadioButton = (
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    attributeType: string
  ) => {
    if (value && requestCart) {
      if (storeId) {
        requestCart.attributes = requestCart.attributes.filter(
          (item) =>
            item.productAttributeMappingId !== value.productAttributeMappingId
        );
        requestCart.attributes.push({
          id: value.id,
          displayOrder: optionDisplayOrder,
          quantity: 1,
          attributeControlTypeId: attributeType,
          productAttributeMappingId: value.productAttributeMappingId,
          type: value.type,
        });
        setRequestCart(requestCart);
        setAttributes(requestCart.attributes);
        if (productInCart) {
          handleUpdateCart(requestCart);
        }
      } else toast.error(t("somethingWentWrongTryAgain"));
      return true;
    }
    return false;
  };

  const handleMultiDropDown = (
    values: IProductAttributeValue[],
    optionId: string,
    optionName: string,
    optionDisplayOrder: number,
    attributeType: string
  ) => {
    if (requestCart) {
      if (storeId) {
        requestCart.attributes = requestCart.attributes.filter(
          (item) => item.attributeControlTypeId !== attributeType
        );
        values.map((attribute) => {
          requestCart.attributes.push({
            id: attribute.id,
            displayOrder: attribute.displayOrder,
            quantity: 1,
            attributeControlTypeId: attributeType,
            productAttributeMappingId: attribute.productAttributeMappingId,
            type: attribute.type,
          });
        });
        setRequestCart(requestCart);
        setAttributes(requestCart.attributes);
        if (productInCart) handleUpdateCart(requestCart);
      } else toast.error(t("somethingWentWrongTryAgain"));
      return true;
    }
    return false;
  };

  const handleQtyChanged = (quantity: number) => {
    if (storeId && requestCart) {
      requestCart.quantity = quantity;
      setRequestCart(requestCart);
      setAttributes(requestCart.attributes);

      handleUpdateCart(requestCart);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };

  const handleNoteChanged = (note: string) => {
    if (storeId && requestCart) {
      requestCart.note = note;
      setRequestCart(requestCart);
      setAttributes(requestCart.attributes);

      handleUpdateCart(requestCart);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };
  // const ReCheckedCheckBoxes = (requestCart:ICartRequest) => {

  // }
  const handleAddAttributeToRequestCart = (
    attribute: IAttributeCartRequest,
    removeOther: boolean,
    forAllSelectCount: number,
    allSelectId: string | undefined,
    limit: number,
    payForMore: boolean
  ) => {
    let attributes: IAttributeCartRequest[] = [];
    let push = true;
    if (requestCart) {
      attributes = requestCart.attributes;
      if (productInCart) {
        if (storeId) {
          if (removeOther) {
            attributes = attributes.filter(
              (a) =>
                a.productAttributeMappingId !==
                attribute.productAttributeMappingId
            );
          } else {
            attributes = attributes.filter(
              (a) =>
                (a.productAttributeMappingId ===
                  attribute.productAttributeMappingId &&
                  a.type !== IproductAttributeValueType.SingleSelect &&
                  a.type !== IproductAttributeValueType.AllSelect) ||
                a.productAttributeMappingId !==
                  attribute.productAttributeMappingId
            );
            if (limit > 0) {
              const currentOptionAttributes = attributes.filter(
                (m) =>
                  m.productAttributeMappingId ===
                  attribute.productAttributeMappingId
              ).length;
              if (limit <= currentOptionAttributes && !payForMore) {
                toast.warn(t("youCanTAddAnyMore"));
                return false;
              }
            }
            if (
              attributes.filter(
                (a) =>
                  a.productAttributeMappingId ===
                  attribute.productAttributeMappingId
              ).length +
                1 >=
                forAllSelectCount &&
              allSelectId !== undefined
            ) {
              attributes = attributes.filter(
                (a) =>
                  a.productAttributeMappingId !==
                  attribute.productAttributeMappingId
              );
              attributes.push({
                attributeControlTypeId: attribute.attributeControlTypeId,
                displayOrder: 0,
                id: allSelectId,
                productAttributeMappingId: attribute.productAttributeMappingId,
                quantity: 1,
                type: IproductAttributeValueType.AllSelect,
              });
              push = false;
            }
          }
          if (push) {
            attributes.push(attribute);
          }
          requestCart.attributes = attributes;
          setRequestCart(requestCart);
          setAttributes(requestCart.attributes);

          handleUpdateCart(requestCart);
        } else toast.error(t("somethingWentWrongTryAgain"));
      } else {
        if (removeOther) {
          attributes = attributes.filter(
            (a) =>
              a.productAttributeMappingId !==
              attribute.productAttributeMappingId
          );
        } else {
          attributes = attributes.filter(
            (a) =>
              a.productAttributeMappingId !==
                attribute.productAttributeMappingId ||
              (a.productAttributeMappingId ===
                attribute.productAttributeMappingId &&
                a.type !== IproductAttributeValueType.SingleSelect &&
                a.type !== IproductAttributeValueType.AllSelect)
          );
          if (limit > 0) {
            const currentOptionAttributes = attributes.filter(
              (m) =>
                m.productAttributeMappingId ===
                attribute.productAttributeMappingId
            ).length;
            if (limit <= currentOptionAttributes && !payForMore) {
              toast.warn(t("youCanTAddAnyMore"));
              return false;
            }
          }
          if (
            attributes.filter(
              (a) =>
                a.productAttributeMappingId ===
                attribute.productAttributeMappingId
            ).length +
              1 >=
              forAllSelectCount &&
            allSelectId !== undefined
          ) {
            attributes = attributes.filter(
              (a) =>
                a.productAttributeMappingId !==
                attribute.productAttributeMappingId
            );
            attributes.push({
              attributeControlTypeId: attribute.attributeControlTypeId,
              displayOrder: 0,
              id: allSelectId,
              productAttributeMappingId: attribute.productAttributeMappingId,
              quantity: 1,
              type: IproductAttributeValueType.AllSelect,
            });
            push = false;
          }
        }
        if (push) {
          attributes.push(attribute);
        }
        requestCart.attributes = attributes;
        setRequestCart(requestCart);
        setAttributes(requestCart.attributes);

        // handleUpdateCart(requestCart);
      }
    }

    return attributes.filter(
      (a) => a.productAttributeMappingId === attribute.productAttributeMappingId
    );
  };

  const handleRemoveAttributeFromRequestCart = (
    attributeId: string
  ): IAttributeCartRequest[] => {
    if (requestCart) {
      if (productInCart) {
        if (storeId) {
          requestCart.attributes = requestCart.attributes.filter(
            (item) => item.id !== attributeId
          );
          setRequestCart(requestCart);
          setAttributes(requestCart.attributes);

          handleUpdateCart(requestCart);
        } else toast.error(t("somethingWentWrongTryAgain"));
      } else {
        requestCart.attributes = requestCart.attributes.filter(
          (item) => item.id !== attributeId
        );
        setRequestCart(requestCart);
        setAttributes(requestCart.attributes);
      }
      return requestCart?.attributes;
    }
    return [];
  };

  const handleAddToCart = () => {
    if (storeId && requestCart) {
      let check = checkForcibleOneSelect(
        product.productAttributeMappings,
        requestCart.attributes
      );
      if (check) {
        handleUpdateCart(requestCart);
        history.replace(`/ordering/departments/${categoryId}`);
      }
    } else toast.error(t("somethingWentWrongTryAgain"));
  };

  const handleUpdateCart = async (model: ICartRequest) => {
    if (information.openOrder || information.preOrder) {
      setLoading(true);
      await agent.Cart.post(model)
        .then((res: ICart) => {
          if (res) {
            dispatch(addToCart(res));
          } else {
            toast.error(t("somethingWentWrongTryAgain"));
          }
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => setLoading(false));
    } else toast.warn(t("onlineOrderClosed"));
  };
  useEffect(() => {
    setRequestCart({
      id: cartId,
      storeId: storeId ?? "",
      isFreebie: false,
      delivery: delivery?.delivery,
      productId: product.id,
      duplicateNum: productInCart ? productInCart.duplicateNum : 0,
      quantity: productInCart ? productInCart.quantity : 1,
      note: note,
      editable: productInCart ? productInCart.editable : true,
      postCode: delivery?.postCode,
      attributes: productInCart
        ? getAttributeCartRequestFromResponse(productInCart.attributes)
        : preSelectAttributes(),
    });
    setAttributes(
      productInCart
        ? getAttributeCartRequestFromResponse(productInCart.attributes)
        : preSelectAttributes()
    );
  }, [product]);
  // =======================useEffect=========================

  if (!product || loading) return <ChildLoading />;
  // ==========================tsx============================
  return (
    <Grid item xs={12} container className={classes.OptionsStyles}>
      <Grid item xs={12}>
        {discountTitles?.map((item) => (
          <Fragment key={item}>
            <Grid item xs={12} className={classes.discountGrid}>
              <Grid item sm={12} md={3} lg={2}>
                <h6>{t("discount")}</h6>
              </Grid>
              <Grid item sm={12} md={9} lg={10}>
                <h6 className="discount">{item}</h6>
              </Grid>
            </Grid>
            <hr />
          </Fragment>
        ))}

        {product.productAttributeMappings
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .filter((m) => m.active && !m.deleted)
          .map((option) => {
            if (option.attributeControlTypeId === "RadioList") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid item sm={12} md={3} lg={2}>
                      <h6>{t(option.productAttributeName)}</h6>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      md={9}
                      lg={10}
                      className={classes.optionsRow}
                    >
                      {option.productAttributeValues
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .filter((m) => m.active)
                        .map((value) => (
                          <Radio1
                            key={value.id}
                            optionDisplayOrder={option.displayOrder}
                            attributeName={option.productAttributeName}
                            productAttributeValue={value}
                            optionId={option.id}
                            handleRadioButon={handleRadioButton}
                            attributeType={option.attributeControlTypeId}
                            isSelected={
                              productInCart
                                ? productInCart.attributes.find(
                                    (m) => m.id === value.id
                                  ) !== undefined
                                : attributes.find((m) => m.id === value.id) !==
                                  undefined
                            }
                            isEditMode={productInCart ? true : false}
                          />
                        ))}
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else if (option.attributeControlTypeId === "DropdownList") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid item sm={12} md={3} lg={2}>
                      <h6>{t(option.productAttributeName)}</h6>
                    </Grid>

                    <Grid item sm={12} md={9} lg={10}>
                      <OptionDropDown
                        productId={product.id}
                        attributeName={option.productAttributeName}
                        productAttributeValues={option.productAttributeValues}
                        optionId={option.id}
                        optionDisplayOrder={option.displayOrder}
                        handleRadioButon={handleRadioButton}
                        attributeType={option.attributeControlTypeId}
                        isInCart={
                          productInCart !== undefined &&
                          productInCart.attributes.find(
                            (m) => m.id === option.id
                          ) !== undefined
                        }
                        duplicateNum={duplicateNum}
                        preSelectedAttr={
                          productInCart?.attributes.filter(
                            (item) =>
                              item.attributeControlTypeId === "DropdownList"
                          )[0]?.id
                        }
                      />
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else if (option.attributeControlTypeId === "Checkboxes") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid item sm={12} md={3} lg={2}>
                      <h6>{t(option.productAttributeName)} </h6>
                      {option.limit > 0 && option.payForMore ? (
                        <span className={classes.toppingDesc}>
                          {t("upTo")}&nbsp;{option.limit}&nbsp;{t("free")}
                        </span>
                      ) : (
                        option.limit > 0 && (
                          <span className={classes.toppingDesc}>
                            {t("limit")}:&nbsp;{option.limit}
                          </span>
                        )
                      )}
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      md={9}
                      lg={10}
                      className={classes.CheckboxContiner}
                    >
                      <div className={classes.checkBoxTags}>
                        {option.productAttributeValues
                          .sort((a, b) => a.displayOrder - b.displayOrder)
                          .filter((m) => m.active && !m.deleted)
                          .map((value) => (
                            <Checkbox1
                              key={value.id}
                              optionDisplayOrder={option.displayOrder}
                              handleCheckBox={handleCheckBox}
                              attributeType={option.attributeControlTypeId}
                              productAttributeValue={value}
                              optionLimit={option.limit}
                              payForMore={option.payForMore}
                              forAllSelectCount={
                                option.productAttributeValues.filter(
                                  (a) =>
                                    a.type !==
                                      IproductAttributeValueType.SingleSelect &&
                                    a.type !==
                                      IproductAttributeValueType.AllSelect
                                ).length
                              }
                              allSelectId={
                                option.productAttributeValues.find(
                                  (a) =>
                                    a.type ===
                                    IproductAttributeValueType.AllSelect
                                )?.id
                              }
                              attributes={attributes}
                            />
                          ))}
                      </div>
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else if (option.attributeControlTypeId === "Checkboxes2") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid
                      item
                      sm={12}
                      md={3}
                      lg={2}
                      className="titleGridOfMultiSelect"
                    >
                      <h6>
                        {t(option.productAttributeName)}{" "}
                        {option.limit > 0 &&
                        option.payForMore &&
                        option.productAttributeValues.length > 0
                          ? `(${money(
                              option.productAttributeValues[0].priceAdjustment
                            )})`
                          : ""}
                      </h6>
                    </Grid>
                    <Grid item sm={12} md={9} lg={10}>
                      <MultiSelectDropdown
                        productId={product.id}
                        attributeName={option.productAttributeName}
                        productAttributeValues={option.productAttributeValues}
                        optionId={option.id}
                        optionDisplayOrder={option.displayOrder}
                        handleMultiDropDown={handleMultiDropDown}
                        attributeType={option.attributeControlTypeId}
                        isInCart={
                          productInCart !== undefined &&
                          productInCart.attributes.find(
                            (m) => m.id === option.id
                          ) !== undefined
                        }
                        duplicateNum={duplicateNum}
                        preSelectedAttr={productInCart?.attributes
                          .filter(
                            (item) =>
                              item.attributeControlTypeId === "Checkboxes2"
                          )
                          .map((item) => item.id)
                          .sort()}
                        attributes={attributes}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else if (option.attributeControlTypeId === "Checkboxes3") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid item sm={12} md={3} lg={2}>
                      <h6>
                        {t(option.productAttributeName)}{" "}
                        {option.limit > 0 &&
                        option.payForMore &&
                        option.productAttributeValues.length > 0
                          ? `(${money(
                              option.productAttributeValues[0].priceAdjustment
                            )})`
                          : ""}
                      </h6>
                    </Grid>
                    <Grid item sm={12} md={9} lg={10}>
                      <FormControl className={classes.extra2Row}>
                        {option.productAttributeValues
                          .sort((a, b) => a.displayOrder - b.displayOrder)
                          .filter((m) => m.active && !m.deleted)
                          .map((value) => (
                            <FormControlLabel
                              key={value.id}
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    const res = handleCheckBox(
                                      value,
                                      option.displayOrder,
                                      e.target.checked,
                                      option.attributeControlTypeId,
                                      option.productAttributeValues.filter(
                                        (a) =>
                                          a.type !==
                                            IproductAttributeValueType.SingleSelect &&
                                          a.type !==
                                            IproductAttributeValueType.AllSelect
                                      ).length,
                                      option.productAttributeValues.find(
                                        (a) =>
                                          a.type ===
                                          IproductAttributeValueType.AllSelect
                                      )?.id,
                                      option.limit,
                                      option.payForMore
                                    );
                                    if (!res) {
                                      e.target.checked = false;
                                    }
                                  }}
                                  name={value.name}
                                  color="primary"
                                  checked={
                                    attributes.find(
                                      (m) => m.id === value.id
                                    ) !== undefined
                                  }
                                  // defaultChecked={
                                  //   (productInCart !== undefined &&
                                  //     productInCart.attributes.find(
                                  //       (m) => m.id === value.id
                                  //     ) !== undefined) ||
                                  //   value.isPreSelected
                                  // }
                                />
                              }
                              label={`${value.name} ${
                                value.priceAdjustment > 0
                                  ? `(${money(value.priceAdjustment)})`
                                  : ""
                              }`}
                              color="primary"
                            />
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else if (option.attributeControlTypeId === "RadioList2") {
              return (
                <Fragment key={option.id}>
                  <Grid item xs={12} className={classes.optionsListGrid}>
                    <Grid item sm={12} md={3} lg={2}>
                      <h6>{t(option.productAttributeName)}</h6>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      md={9}
                      lg={10}
                      className={classes.optionsRow}
                    >
                      {option.productAttributeValues
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .filter((m) => m.active)
                        .map((value) => (
                          <Radio2
                            key={value.id}
                            optionDisplayOrder={option.displayOrder}
                            attributeName={option.productAttributeName}
                            productAttributeValue={value}
                            optionId={option.id}
                            handleRadioButon={handleRadioButton}
                            attributeType={option.attributeControlTypeId}
                            isInCart={
                              productInCart !== undefined &&
                              productInCart.attributes.find(
                                (m) => m.id === value.id
                              ) !== undefined
                            }
                          />
                        ))}
                    </Grid>
                  </Grid>
                  <hr />
                </Fragment>
              );
            } else return null;
          })}

        {productInCart && (
          <Grid item xs={12} className={classes.optionsListGrid}>
            <Grid item sm={12} md={3} lg={2}>
              <h6>{t("quantity")}</h6>
            </Grid>
            <Grid item sm={12} md={9} lg={10}>
              <ProductCounter
                quantity={productInCart.quantity}
                handleUpdateCart={handleQtyChanged}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={6} className={classes.orderBtn}>
        <ModifyOrderNote
          note={note}
          onChange={(newNote) => setNote(newNote)}
          onSubmitClicked={(newNote) => handleNoteChanged(newNote)}
          onCancelClicked={() =>
            setNote(productInCart ? productInCart.note : "")
          }
        />
        <Hidden mdUp>
          <hr />
        </Hidden>
      </Grid>

      <Grid item xs={12} md={6} className={classes.actions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.replace(`/ordering/departments/${categoryId}`)}
        >
          {t("back")}
        </Button>
        <Button
          className="addToCart"
          disabled={productInCart !== undefined}
          variant="contained"
          color="primary"
          onClick={handleAddToCart}
        >
          {t("addToCart")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Options;

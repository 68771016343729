import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const OpeningTimesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",

      "& h3": {
        textAlign: "center",
        color: theme.palette.text.primary,
      },

      "& p, h5, h4": {
        display: "block",
        textAlign: "center",
        color: theme.palette.text.primary,
      },
      "& .closed": {
        color: "#CC0000",
        borderRadius: 3,
        backgroundColor: "#fff",
        padding: "2px 6px",
      },
    },
    paper: {
      padding: "1rem",
    },
    tableContainer: {
      [theme.breakpoints.down("sm")]: {
        order: 3,
      },
    },
    table: {
      border: `1px solid ${theme.palette.text.primary}`,
      "& td": {
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
    timeContainer: {
      [theme.breakpoints.down("sm")]: {
        order: 2,
      },
      "& .flex": {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
      },
    },
    time: {
      width: "50%",
      padding: "1rem",
      marginBottom: ".5rem",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "& h6": {
        margin: 0,
        textAlign: "center",
      },
    },
    today: {
      "& .closed": {
        padding: "5px 10px",
        fontWeight: 600,
      },

      [theme.breakpoints.up("lg")]: {
        "& p": {
          width: "70%",
          margin: "0 auto",
        },
      },
    },
  })
);

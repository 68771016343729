import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IImageWebSite } from "../../../Models/ImageWebSite";
import agent from "../../../Services/agent";
import { selectInformation } from "../../../Stores/informationSlice";
import Loading from "../../Loading/Loading";

const BackgroundImage = () => {
  const [bgImg, setBgImg] = useState<IImageWebSite>();
  const [loading, setLoading] = useState(false);
  const information = useSelector(selectInformation);

  const getBackground = async () => {
    setLoading(true);
    await agent.ImageWebSite.getMiddleMainPage()
      .then((res) => {
        if (res) setBgImg(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getBackground();
  }, []);

  if (loading) return <Loading />;
  let img = null;
  if (bgImg) {
    img = (
      <div
        style={{
          filter: information.darkTheme ? "brightness(0.5)" : "opacity(60%)",
          backgroundColor: information.backGroundColor,
          position: "fixed",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      >
        <img
          src={`${process.env.REACT_APP_IMG_URL}${bgImg?.picPath}`}
          alt={bgImg?.title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }

  return img;
};

export default BackgroundImage;

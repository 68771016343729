import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const SubmitBookingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      padding: "0 10px 10px 10px",
      margin: 0,
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    formControl: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      marginBottom: 30,
    },
    selectFormControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputs: {
      [theme.breakpoints.up("lg")]: {
        padding: 10,
      },
    },
    time: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        alignItems: "center",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "space-between",
        alignItems: "center",
      },

      "& .MuiButtonGroup-root": {
        margin: "15px 0 10px 0",
      },
    },
    note: {
      [theme.breakpoints.up("lg")]: {
        paddingBottom: 14,
      },
    },
    action: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      "& button": {
        margin: 10,
      },
    },
  })
);

import React from "react";
import { Button, ClickAwayListener, IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import iosShare from "../../../assets/img/ios share button.svg";
import { AddToHomeScreenStyles } from "./AddToHomeScreenStyles";
import { useTranslation } from "react-i18next";
import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";
import { isDesktop, isMobile, isAndroid, isIOS } from "react-device-detect";

const AddToHomeScreen = () => {
  const { t } = useTranslation();
  const classes = AddToHomeScreenStyles();
  const [open, setOpen] = React.useState(true);
  const isShowed = localStorage.getItem("pwaTrigger");

  // const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  // const [isVisible, setVisibleState] = React.useState(false);
  // const hide = () => setVisibleState(false);
  // React.useEffect(() => {
  //   if (prompt) {
  //     setVisibleState(true);
  //   }
  // },
  //   [prompt]
  // );
  // if (!isVisible) {
  //   return <div />;
  // }

  if (!isShowed) {
    let deferredPrompt: any;
    const addBtn = document.querySelector(".add-button") as HTMLElement;
    const closeBtn = document.querySelector(".close") as HTMLElement;
    const installContainer = document.querySelector(
      ".installContainer"
    ) as HTMLElement;
    if (installContainer) {
      installContainer.style.display = "none";

      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        installContainer.style.display = "block";

        closeBtn.addEventListener("click", (e) => {
          installContainer.style.display = "none";
        });

        addBtn.addEventListener("click", (e) => {
          // hide our user interface that shows our A2HS button
          installContainer.style.display = "none";
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");
            } else {
              console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt = null;
          });
        });
      });
    }
  }

  // function getOS() {
  //   var userAgent = window.navigator.userAgent,
  //     platform = navigator.userAgentData?.platform,
  //     macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  //     windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  //     iosPlatforms = ["iPhone", "iPad", "iPod"],
  //     os = null;

  //   if (platform && macosPlatforms.indexOf(platform) !== -1) {
  //     os = "Mac OS";
  //   } else if (platform && iosPlatforms.indexOf(platform) !== -1) {
  //     os = "iOS";
  //   } else if (platform && windowsPlatforms.indexOf(platform) !== -1) {
  //     os = "Windows";
  //   } else if (/Android/.test(userAgent)) {
  //     os = "Android";
  //   } else if (platform && !os && /Linux/.test(platform)) {
  //     os = "Linux";
  //   }

  //   return os;
  // }

  const HandlePwaTrigger = () => {
    localStorage.setItem("pwaTrigger", "true");
    // window.location.reload();
    setOpen(false);
  };

  if (!isMobile) return <div />;

  return (
    <div className={classes.root}>
      {isAndroid && (isShowed === null || isShowed === "false") ? (
        <div className="installContainer">
          <IconButton
            onClick={() => HandlePwaTrigger()}
            className="close android"
          >
            <CloseRoundedIcon color="error" />
          </IconButton>
          <h5>{t("addAppToHomeScreen")}</h5>
          <Button
            onClick={() => HandlePwaTrigger()}
            variant="contained"
            color="secondary"
            className="add-button"
          >
            {t("install")}
          </Button>
        </div>
      ) : (isShowed === null || isShowed === "false") && open && (
        <div className="background">
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <div className="installContainerIos">
              <IconButton
                size="small"
                color="secondary"
                className={`ok ${classes.closeBtn}`}
                onClick={() => setOpen(false)}
              >
                <CloseRoundedIcon />
              </IconButton>
              <h5>{t("addWebApplicationToHomeScreen")}</h5>
              <hr />
              <div className="description">
                <p>
                  1. {t("tapShare")} <img src={iosShare} alt="button" />
                  {t("inToolbar")}.
                </p>
                <p>2. {t("tapAddToHomeScreenButton")}.</p>
                <p>3. {t("tapAddButtonAtTheTopOfScreen")}</p>
              </div>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                className="ok"
                onClick={() => HandlePwaTrigger()}
              >
                {t("ok")}
              </Button>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
}

//   return (
//     <>
//       {
//         isAndroid ? (
//           <div onClick={hide}>
//             <button onClick={hide}>Close</button>
//             Hello! Wanna add to homescreen?
//             <button onClick={promptToInstall}>Add to homescreen</button>
//           </div>
//         )
//           :
//           isDesktop && (
//             <div className="background">
//               <ClickAwayListener
//                 onClickAway={hide}
//               >
//                 <div className="installContainerIos">
//                   <IconButton
//                     size="small"
//                     color="secondary"
//                     className={`ok ${classes.closeBtn}`}
//                     onClick={hide}
//                   >
//                     <CloseRoundedIcon />
//                   </IconButton>
//                   <h5>{t("addWebApplicationToHomeScreen")}</h5>
//                   <hr />
//                   <div className="description">
//                     <p>
//                       1. {t("tapShare")} <img src={iosShare} alt="button" />
//                       {t("inToolbar")}.
//                     </p>
//                     <p>2. {t("tapAddToHomeScreenButton")}.</p>
//                     <p>3. {t("tapAddButtonAtTheTopOfScreen")}</p>
//                   </div>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     fullWidth
//                     size="large"
//                     className="ok"
//                     onClick={hide}
//                   >
//                     {t("ok")}
//                   </Button>
//                 </div>
//               </ClickAwayListener>
//             </div>
//           )
//       }
//     </>
//   );
// }

{/* <div className={classes.root}>
  {getOS() === "Android" && (isShowed === null || isShowed === "false") ? (
    <div className="installContainer">
      <IconButton
        onClick={() => HandlePwaTrigger()}
        className="close android"
      >
        <CloseRoundedIcon color="error" />
      </IconButton>
      <h5>{t("addAppToHomeScreen")}</h5>
      <Button
        onClick={() => HandlePwaTrigger()}
        variant="contained"
        color="secondary"
        className="add-button"
      >
        {t("install")}
      </Button>
    </div>
  ) : getOS() === "iOS" && (isShowed === null || isShowed === "false") && open && (
    <div className="background">
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div className="installContainerIos">
          <IconButton
            size="small"
            color="secondary"
            className={`ok ${classes.closeBtn}`}
            onClick={() => setOpen(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
          <h5>{t("addWebApplicationToHomeScreen")}</h5>
          <hr />
          <div className="description">
            <p>
              1. {t("tapShare")} <img src={iosShare} alt="button" />
              {t("inToolbar")}.
            </p>
            <p>2. {t("tapAddToHomeScreenButton")}.</p>
            <p>3. {t("tapAddButtonAtTheTopOfScreen")}</p>
          </div>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            size="large"
            className="ok"
            onClick={() => HandlePwaTrigger()}
          >
            {t("ok")}
          </Button>
        </div>
      </ClickAwayListener>
    </div>
  )}
</div> */}

export default AddToHomeScreen;

import React from "react";
import { FieldRenderProps } from "react-final-form";
import { TextField } from "@material-ui/core";

interface IProps extends FieldRenderProps<string, HTMLElement> {
  placeholder: string;
  label: string;
  type: string;
  rows?: number;
  rowsMax?: number;
  noFullWidth?: false;
  required?: true;
  disable?: true;
}

const TextInput: React.FC<IProps> = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
  rows,
  rowsMax,
  noFullWidth,
  required,
  disable,
}) => {
  return (
    <TextField
      id={label}
      label={label}
      variant="outlined"
      type={type}
      {...input}
      onChange={(event) => {
        input.onChange(event.target.value);
      }}
      helperText={error}
      error={touched && error}
      size="small"
      fullWidth={noFullWidth === false ? false : true}
      margin="normal"
      multiline={rows ? true : false}
      rows={rows}
      rowsMax={rowsMax}
      required={required}
      disabled={disable}
    />
  );
};

export default TextInput;

import React, { Fragment } from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Collapse from "@material-ui/core/Collapse";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import { Button, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../Stores/userSlice";
import { useTranslation } from "react-i18next";
import { DrawerMenuStyles } from "./DrawerMenuStyles";

interface IMenuLink {
  name: string;
  to: string;
}
interface IProps {
  menu: IMenuLink[];
  address: string;
  tel: string;
}

const DrawerMenu: React.FC<IProps> = ({ menu, address, tel }) => {
  const { t } = useTranslation();
  const classes = DrawerMenuStyles();
  // const [listCollapse, setListCollapse] = React.useState("");

  // const handleClick = (name: string) => {
  //   if (name === listCollapse) {
  //     setListCollapse("");
  //   } else setListCollapse(name);
  // };

  const user = useSelector(selectUser);
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;
  const isUser = user && user.token;
  return (
    <Fragment>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <div className={classes.links}>
          {/* <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader> */}
          {window.location.pathname === "/" ? (
            <Link href="/ordering/departments" underline="none">
              <ListItem>
                <ListItemText primary={t("orderNow")} />
              </ListItem>
            </Link>
          ) : (
            <Link href="/" underline="none">
              <ListItem>
                <ListItemText primary={t("home")} />
              </ListItem>
            </Link>
          )}
          {menu.map((item) => {
            return (
              <Link key={item.name} href={item.to} underline="none">
                <ListItem button>
                  <ListItemText primary={t(item.name)} />
                </ListItem>
              </Link>
            );
          })}
          {isUser && user.roles.indexOf("Guest") === -1 ? (
            <Fragment>
              {isAdmin && (
                <Link href="/panel" underline="none">
                  <ListItem>
                    <ListItemText primary={t("admin")} />
                  </ListItem>
                </Link>
              )}

              <Link href="/profile" underline="none">
                <ListItem>
                  <ListItemText primary={t("profile")} />
                </ListItem>
              </Link>
              <Link
                href="/"
                onClick={() => {
                  localStorage.removeItem("account");
                  window.location.replace("/");
                }}
                underline="none"
              >
                <ListItem>
                  <ListItemText primary={t("signOut")} />
                </ListItem>
              </Link>
            </Fragment>
          ) : (
            <Link href="/login" underline="none">
              <ListItem>
                <ListItemText primary={t("signIn")} />
              </ListItem>
            </Link>
          )}
        </div>

        <div>
          <ListItem className={classes.info}>
            <div dangerouslySetInnerHTML={{ __html: tel }}></div>
            <div dangerouslySetInnerHTML={{ __html: address }}></div>
            <hr />
            <div className="addApp">
              <p>{t("addWebApplicationToHomeScreen")}?</p>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
                className="add-button"
                onClick={() => {
                  localStorage.setItem("pwaTrigger", "false");
                  window.location.reload();
                }}
              >
                {t("add")}
              </Button>
            </div>
          </ListItem>
        </div>
      </List>
    </Fragment>
  );
};

export default DrawerMenu;

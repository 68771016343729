import React from "react";
import "./simpleArrow.scss";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
const SimpleNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <ArrowForwardIosRoundedIcon
      className="simpleArrow next"
      fontSize="large"
      onClick={onClick}
    />
  );
};

export default SimpleNextArrow;

import { useState, useEffect } from "react";
import Slider from "react-slick";

import { Grid } from "@material-ui/core";

import CustomPaging from "../../Common/CustomPaging/CustomPaging";
import SimpleNextArrow from "../../Common/SimpleArrow/SimpleNextArrow";
import SimpleBackArrow from "../../Common/SimpleArrow/SimpleBackArrow";
import { IImageWebSite } from "../../../Models/ImageWebSite";
import agent from "../../../Services/agent";

import { Carousel2Styles } from "./Carousel2Styles";

const Carousel2 = () => {
  const classes = Carousel2Styles();
  const [banners, setbanners] = useState<IImageWebSite[]>([]);

  const getBanners = async () => {
    await agent.ImageWebSite.getSliders().then((res) => setbanners(res));
  };

  useEffect(() => {
    getBanners();
  }, []);

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    row: 1,
    customPaging: CustomPaging,
    arrows: false,
    nextArrow: <SimpleNextArrow />,
    prevArrow: <SimpleBackArrow />,
    autoplay: true,
    className: "carouselHeight",
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <Grid container className={classes.carousel2}>
      <Grid item xs={12}>
        {banners.length > 0 && (
          <Slider {...settings}>
            {banners.map((item) => (
              <img
                key={item.id}
                src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                alt={item.title}
              />
            ))}
          </Slider>
        )}
      </Grid>
    </Grid>
  );
};

export default Carousel2;

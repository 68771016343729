import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";

import payPal from "../../../assets/img/paypal.png";
import visa from "../../../assets/img/visa.png";
import masterCard from "../../../assets/img/mastercard.png";
import maestro from "../../../assets/img/maestro.png";
import amex from "../../../assets/img/american-express.png";

import { Footer2Styles } from "./Footer2Styles";
import { TikTokIcon } from "../../../utils/MaterialUi/TikTokIcon";

interface IProps {
  facebook: string;
  twitter: string;
  instagram: string;
  whatsapp: string;
  tiktok: string;
  hasOnlinePayment: boolean;
  hasPayPal: boolean;
  copyWrite: string;
}

const Footer2: React.FC<IProps> = ({
  facebook,
  twitter,
  instagram,
  whatsapp,
  tiktok,
  hasOnlinePayment,
  hasPayPal,
  copyWrite,
}) => {
  const { t } = useTranslation();
  const classes = Footer2Styles();

  return (
    <Grid container className={classes.footer2}>
      <Grid item xs={12} lg={3} xl={4} className={classes.carts}>
        {hasOnlinePayment && (
          <>
            {hasPayPal && <img src={payPal} alt="Pay Pal" />}
            <img src={visa} alt="Visa" />
            <img src={masterCard} alt="Master Card" />
            <img src={maestro} alt="Maestro" />
            <img src={amex} alt="AMEX" />
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
        className={`${classes.footerLinks} ${classes.footerLinksForMUIChangesOfLanguage}`}
      >
        <NavLink to="/about">{t("aboutUs")}</NavLink>
        <NavLink to="/allergy-advice">{t("allergyAdvice")}</NavLink>
        <NavLink to="/conditions">{t("termsConditions")}</NavLink>
        <NavLink to="/privacy">{t("privacy")}</NavLink>
      </Grid>
      <Grid item xs={12} lg={3} xl={4} className={classes.socialLinks}>
        {facebook && (
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <FacebookIcon fontSize="large" />
          </a>
        )}
        {instagram && (
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <InstagramIcon fontSize="large" />
          </a>
        )}
        {twitter && (
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <TwitterIcon fontSize="large" />
          </a>
        )}
        {whatsapp && (
          <a href={whatsapp} target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon fontSize="large" />
          </a>
        )}
        {tiktok && (
          <a href={tiktok} target="_blank" rel="noopener noreferrer">
            <TikTokIcon color="blue" />
          </a>
        )}
      </Grid>
      <Grid item xs={12} className={classes.copyRight}>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          align="center"
          className="copyRight"
        >
          {copyWrite ? copyWrite : `${t("copyright")}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer2;

export interface IAttributeCartRequest {
  id: string;
  displayOrder: number;
  quantity: number;
  attributeControlTypeId: string;
  productAttributeMappingId: string;
  type: IproductAttributeValueType;
}

export enum IproductAttributeValueType {
  Simple = "Simple",
  MultiSelect = "MultiSelect",
  SingleSelect = "SingleSelect",
  NoItem = "NoItem",
  AllSelect = "AllSelect",
}

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const AddToHomeScreenStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .installContainer": {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "99%",
        height: 150,
        borderRadius: 5,
        backgroundColor: "#fff",
        padding: 20,
        margin: 2,
        zIndex: 1301,
        "& .add-button": {
          display: "block",
          float: "right",
        },
        "& .close.android": {
          position: "absolute",
          top: 10,
          right: 10,
        },
        "& h5": {
          display: "block",
          margin: "20px 0",
        },
      },
      "& .installContainerIos": {
        position: "absolute",
        bottom: 1,
        left: 0,
        width: "99%",
        height: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: 5,
        backgroundColor: "#fff",
        padding: 15,
        margin: 2,
        zIndex: 1301,
        "& .logo": {
          height: 70,
          objectFit: "contain",
          width: 500,
          display: "block",
        },
        "& h5": {
          fontSize: "1.2rem",
          textAlign: "center",
          marginBottom: 10,
        },
        "& hr": {
          width: "100%",
          marginBottom: 60,
        },
        "& .description": {
          "@media(min-width: 321px)": {
            padding: "0 20px",
          },
        },
        "& img": {
          display: "inline-block",
          width: 20,
          height: 20,
        },
        "& button:last-child": {
          marginTop: 60,
        },
      },
      "& .background": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        backgroundColor: "#00000050",
      },
    },
    closeBtn: {
      position: "absolute",
      top: 11,
      right: 10,
    },
  })
);

import React from "react";
import { NavLink } from "react-router-dom";
import { Paper } from "@material-ui/core";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import MyLocationRoundedIcon from "@material-ui/icons/MyLocationRounded";
import { useTranslation } from "react-i18next";
import {ProfileMenuStyles} from './ProfileMenuStyles';

const ProfileMenu = () => {
  const classes = ProfileMenuStyles();
  const { t } = useTranslation();

  return (
    <Paper square className={classes.profileMenu + " box"}>
      <ul>
        <li className="profileMenu-title ">{t("userProfile")}</li>
        <li className="profileMenu-item">
          <NavLink to="/profile" exact activeClassName="isActive">
            <AccountBoxRoundedIcon />
            {t("profileAccount")}
          </NavLink>
        </li>
        <li className="profileMenu-item">
          <NavLink to="/profile/password" activeClassName="isActive">
            <LockRoundedIcon />
            {t("password")}
          </NavLink>
        </li>
        <li className="profileMenu-item">
          <NavLink to="/profile/orders" activeClassName="isActive">
            <LocalMallRoundedIcon />
            {t("orders")}
          </NavLink>
        </li>
        <li className="profileMenu-item">
          <NavLink to="/profile/addresses" activeClassName="isActive">
            <MyLocationRoundedIcon />
            {t("addresses")}
          </NavLink>
        </li>
      </ul>
    </Paper>
  );
};

export default ProfileMenu;

import React from "react";
import "./simpleArrow.scss";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
const SimpleBackArrow = (props: any) => {
  const { onClick } = props;
  return (
    <ArrowBackIosRoundedIcon
      className="simpleArrow"
      fontSize="large"
      onClick={onClick}
    />
  );
};

export default SimpleBackArrow;

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";

import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";

import {
  combineValidators,
  isRequired,
  composeValidators,
  matchesField,
} from "revalidate";
import { FORM_ERROR } from "final-form";

import { IRegister } from "../../Models/user";
import agent from "../../Services/agent";
import TextInput from "../../utils/TextInput";
import ErrorMessage from "../../utils/ErrorMessage";
import { isValidEmail } from "../../Services/CustomValidate";
import { setToken } from "../../Stores/userSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
    },
    button: {
      margin: "1rem",
    },
    paper: {
      padding: "1rem",
      margin: "1rem",
    },
  })
);

interface IProps {
  handleSubmit: () => void;
}

const SignUp: React.FC<IProps> = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const validate = combineValidators({
    email: composeValidators(
      isValidEmail(),
      isRequired({ message: t("required") })
    )(),
    firstName: isRequired({ message: t("required") }),
    tell: isRequired({ message: t("required") }),
    password: isRequired({ message: t("required") }),
    confirmPassword: matchesField(
      "password",
      "Password"
    )({
      message: t("passwordsDoNotMatch"),
    }),
  });
  const dispatch = useDispatch();
  const registerForm: IRegister = {
    confirmPassword: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    userName: "",
    tell: "",
  };
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4} className={classes.root}>
      <Form
        validate={validate}
        initialValues={registerForm}
        onSubmit={(values: IRegister) =>
          agent.User.register(values)
            .then((res) => {
              toast.success(t("yourAccountCreatedSuccessfully"));
              dispatch(setToken(res));
              handleSubmit();
            })
            .catch((error) => ({
              [FORM_ERROR]: error,
            }))
        }
        render={({
          handleSubmit,
          invalid,
          pristine,
          submitting,
          form,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
              <h3>{t("register")}</h3>
              {/* {submitError && submitError.data.errors && !dirtySinceLastSubmit && ( */}
              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage text={t("error(s)")} error={submitError} />
              )}
              <Field
                label={t("emailAddress")}
                autoFocus
                name="email"
                component={TextInput}
                placeholder={t("emailAddress")}
                required
              />
              <Field
                label={t("firstName")}
                name="firstName"
                component={TextInput}
                placeholder={t("firstName")}
                required
              />
              <Field
                label={t("lastName")}
                name="lastName"
                component={TextInput}
                placeholder={t("lastName")}
              />
              <Field
                label={t("tel")}
                name="tell"
                component={TextInput}
                placeholder={t("tel")}
                required
              />
              <Field
                label={t("password")}
                name="password"
                component={TextInput}
                type="password"
                placeholder={t("password")}
                required
              />
              <Field
                label={t("confirmPassword")}
                name="confirmPassword"
                type="password"
                component={TextInput}
                placeholder={t("confirmPassword")}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
              >
                {t("register")}
              </Button>
              {/* <pre dir="ltr">{JSON.stringify(form.getState(), null, 2)}</pre> */}
            </Paper>
          </form>
        )}
      />
    </Grid>
  );
};

export default SignUp;

import React, { useState } from "react";
import { IProductAttributeValue } from "../../../Models/productAttributeValue";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectInformation, selectMoney } from "../../../Stores/informationSlice";
import { selectCart } from "../../../Stores/redisCartSlice";
interface IProps {
  productAttributeValues: IProductAttributeValue[];
  attributeName: string;
  optionId: string;
  handleRadioButon(
    value: IProductAttributeValue,
    optionDisplayOrder: number,
    attributeType: string
  ): boolean;
  attributeType: string,
  isInCart: boolean;
  productId: string;
  optionDisplayOrder: number;
  duplicateNum: number;
  preSelectedAttr?: string;
}
const OptionDropDown: React.FC<IProps> = ({
  productAttributeValues,
  attributeName,
  optionId,
  handleRadioButon,
  attributeType,
  isInCart,
  productId,
  optionDisplayOrder,
  duplicateNum,
  preSelectedAttr,
}) => {
  const productInCart = useSelector(selectCart);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>();
  const money = useSelector(selectMoney);
  const information = useSelector(selectInformation);

  return (
    <FormControl fullWidth margin="none">
      <TextField
        select
        variant="outlined"
        value={preSelectedAttr ? preSelectedAttr : selectedValue}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          if (event.target.value) {
            const val = event.target.value as string;
            const value = productAttributeValues.find((a) => a.id === val);
            if (value !== undefined) {
              setSelectedValue(value.id);
              handleRadioButon(value, optionDisplayOrder, attributeType);
            }
          }
        }}
        label={`${t("selectA")} ${attributeName}`}
        size="small"
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: information.langDisplay === "en-GB" ? "left" : "right",
            },
            getContentAnchorEl: null,
          },
        }}
      >
        <MenuItem disabled value={undefined}>
          {t("selectA")} {attributeName}
        </MenuItem>
        {productAttributeValues
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .filter((m) => m.active)
          .map((option: IProductAttributeValue) => (
            <MenuItem
              key={option.id}
              value={option.id}
              className="optionDropDownItem"
            >
              {`${option.name} ${option.priceAdjustment === 0
                ? ""
                : money(option.priceAdjustment)
                }`}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};

export default OptionDropDown;

import { useTranslation } from "react-i18next";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    textField: {
      margin: 20,
      width: "25ch",
    },
    btn: {
      margin: 20,
    },
  })
);

export default function EditBooking() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <TextField
              label={t("resevationRefrence")}
              id="margin-none"
              defaultValue="Default Value"
              className={classes.textField}
            />
            <TextField
              label={t("surname")}
              id="margin-none"
              defaultValue="Default Value"
              className={classes.textField}
            />
            <div style={{ marginLeft: 50, padding: 20 }}>
              <h5>{t("choose one number :")}</h5>
              <div>
                <Checkbox
                  value="checkedA"
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
                <TextField id="standard-basic" label={t("email")} />
              </div>
              <div>
                <Checkbox
                  value="checkedA"
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
                <TextField id="standard-basic" label={t("phoneNumber")} />
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
              >
                {t("save")}
              </Button>
              <Button variant="contained" color="primary">
                {t("cancel")}
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Card>
              <CardContent>{t("email")} </CardContent>
              <CardContent>{t("name")} </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

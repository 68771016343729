import { Button, Grid, TextField } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectInformation } from "../../../Stores/informationSlice";
import CustomModal from "../../Modal/CustomModal";

interface IProps {
  note: string;
  onChange(v: string): void;
}
const ModifyOrderNote: React.FC<IProps> = ({ note, onChange }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const information = useSelector(selectInformation);

  return (
    <Fragment>
      <CustomModal
        title={t("orderNote")}
        open={modal}
        handleShow={() => setModal(false)}
        fullWidth={true}
        maxWidth="sm"
        body={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span>
                {t(
                  "donTLeaveAllergyInformationHereAlwaysContactUsIfYouHaveAnAllergy"
                )}
              </span>
              {information.langDisplay === "fa-IR" && <span>{t("details")}</span>}
              &nbsp;
              <Link to="/contact">{t("contact")}</Link>&nbsp;
              {information.langDisplay === "en-GB" && <span>{t("details")}.</span>}
              {information.langDisplay === "fa-IR" && <span>{t("see")}.</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={note}
                onChange={(e) => onChange(e.target.value)}
                label={t("note")}
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                color="primary"
              />
            </Grid>
          </Grid>
        }
        action={
          <Fragment>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModal(false)}
              style={{ margin: 10 }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onChange(note);
                setModal(false);
              }}
            >
              {t("submit")}
            </Button>
          </Fragment>
        }
      />
      <Button
        onClick={() => setModal(true)}
        variant="outlined"
        color="default"
        className="orderNoteBtn"
      >
        {t("orderNote")}
      </Button>
    </Fragment>
  );
};

export default ModifyOrderNote;

import createGenericSlice from "./GenericSlice";
import { GenericState } from "../Models/state";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICommon } from "../Models/common";

export const slice = createGenericSlice({
  name: "common",
  initialState: {
    status: "loading",
    data: {
      categoryId: GetCommon().categoryId,
    },
  } as GenericState<ICommon>,
  reducers: {
    setCategoryId: (state, action: PayloadAction<ICommon>) => {
      state.status = "loading";
      state.data = action.payload;
      state.status = "finished";
      window.localStorage.setItem("common", JSON.stringify(action.payload));
    },
    removeCategoryId: (state) => {
      if (state.data) {
        state.data.categoryId = undefined;
        window.localStorage.removeItem("common");
      }
    },
  },
});
export const selectCategoryId = (state: { common: { data: ICommon } }) =>
  state.common.data.categoryId;
export const { setCategoryId, removeCategoryId } = slice.actions;
export default slice.reducer;
function GetCommon(): ICommon {
  const val = window.localStorage.getItem("common");
  let common: ICommon = {
    categoryId: undefined,
  };
  if (val) {
    common = JSON.parse(val);
  }
  return common;
}

import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Grid,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  IconButton,
  Button,
  Paper,
  Hidden,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { CartStyles } from "./Cart2Styles";
import {
  selectInformation,
  selectMoney,
} from "../../../Stores/informationSlice";
import { selectCart } from "../../../Stores/redisCartSlice";
import { history } from "../../..";
import ProductCounter from "../../Options/ProductCounter/ProductCounter";
import { selectUser } from "../../../Stores/userSlice";

const Cart2 = () => {
  const dispatch = useDispatch();
  const cartList = useSelector(selectCart);
  const user = useSelector(selectUser);
  const information = useSelector(selectInformation);
  const money = useSelector(selectMoney);
  const { t } = useTranslation();
  const classes = CartStyles();

  return (
    <Paper elevation={0}>
      <Grid container justifyContent="center" className={classes.cart2}>
        <Grid item xs={12} xl={8} className="finalText">
          <h3>{t("confirmYourOrder")}</h3>
          <p>{t("checkoutYourOrder")}</p>
        </Grid>
        <Grid item xs={12} xl={8}>
          <Hidden xsDown>
            <TableContainer className="productTable">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("qty")}</TableCell>
                    <TableCell>{t("product")}</TableCell>
                    <TableCell>{t("price")}</TableCell>
                    <TableCell>{t("total")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartList.userCart.carts.map((item) => (
                    <TableRow key={item.productId}>
                      <TableCell>
                        <ProductCounter
                          duplicateNum={item.duplicateNum}
                          id={item.productId}
                          quantity={item.quantity}
                        />
                      </TableCell>
                      <TableCell>
                        {item.name}
                        <ul className={classes.extra}>
                          {item.attributes
                            .slice()
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((att) => (
                              <li key={att.id}>{att.name}</li>
                            ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        {money(item.price)}
                        <ul className="extraPrice">
                          {item.attributes
                            .slice()
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((att) => (
                              <li key={att.id}>
                                <span className="price currency">
                                  {att.price === 0 ? (
                                    <span>.</span>
                                  ) : (
                                    money(att.price)
                                  )}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </TableCell>
                      <TableCell className="totalBold">
                        {money(
                          (item.attributes
                            .map((attr) => attr.price)
                            .reduce((prev, curr) => prev + curr, 0) +
                            item.price) *
                            item.quantity
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label={t("deleteProduct")}
                          onClick={() => {
                            // dispatch(
                            //   removeFromCart({
                            //     duplicateNum: item.duplicateNum,
                            //     id: item.id,
                            //     quantity: item.quantity,
                            //   })
                            // )
                          }}
                        >
                          <CloseRoundedIcon
                            style={{ color: "red" }}
                            fontSize="large"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Hidden smUp>
            <TableContainer className="productTableXS">
              <Table>
                <TableBody>
                  {cartList.userCart.carts.map((item) => (
                    <Fragment key={item.productId}>
                      <TableRow>
                        <TableCell>{t("product")}</TableCell>
                        <TableCell>
                          {item.name}
                          <ul className="extra">
                            {item.attributes
                              .slice()
                              .sort((a, b) => a.displayOrder - b.displayOrder)
                              .map((att) => (
                                <li key={att.id}>
                                  {att.name}
                                  <span className="extraPrice">
                                    {att.price === 0 ? null : money(att.price)}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("qty")}</TableCell>
                        <TableCell className="flexRow">
                          <ProductCounter
                            duplicateNum={item.duplicateNum}
                            id={item.productId}
                            quantity={item.quantity}
                          />
                          <IconButton
                            aria-label={t("deleteProduct")}
                            onClick={() => {
                              // dispatch(
                              //   removeFromCart({
                              //     duplicateNum: item.duplicateNum,
                              //     id: item.id,
                              //     quantity: item.quantity,
                              //   })
                              // )
                            }}
                          >
                            <CloseRoundedIcon style={{ color: "red" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow className="itemEnd">
                        <TableCell>{t("total")}</TableCell>
                        <TableCell className="totalBold">
                          {money(
                            (item.attributes
                              .map((attr) => attr.price)
                              .reduce((prev, curr) => prev + curr, 0) +
                              item.price) *
                              item.quantity
                          )}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
        </Grid>
        <Grid item xs={12} xl={8} className="nextBtn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (!information.openOrder || information.temporaryShopClose) {
                toast.warn(t("shopIsCloseNow"));
              } else if (cartList.userCart.total < information.minimumOrder) {
                toast.warn(
                  t(`minimumOrderIs ${information.minimumOrder.toFixed(2)}`)
                );
              } else history.goBack();
            }}
          >
            {t("back")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (
                user.token === "" ||
                user.token === null ||
                user.token === undefined
              ) {
                history.push("/login/checkout");
              } else if (
                !information.openOrder ||
                information.temporaryShopClose
              ) {
                toast.warn(t("shopIsCloseNow"));
              } else if (cartList.userCart.total < information.minimumOrder) {
                toast.warn(
                  t(`minimumOrderIs ${information.minimumOrder.toFixed(2)}`)
                );
              } else history.push("/address");
            }}
          >
            {t("next")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Cart2;

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const LandingPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1rem",
      "@media only screen and (max-width: 600px)": {
        alignItems: "space-between",
      },

      "@media only screen and (min-width: 600px)": {},

      "@media only screen and (min-width: 960px)": {},

      "@media only screen and (min-width: 1280px)": {},

      "@media only screen and (min-width: 1920px)": {},

      "& .info": {
        width: "100%",
        padding: "1rem 1.5rem",
        color: theme.palette.text.primary,
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },

        [theme.breakpoints.up("lg")]: {},
        "& h2": {
          margin: 0,
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            fontSize: "2.2rem",
          },
        },
        "& h1": {
          color: theme.palette.primary.main,
          lineHeight: 0.9,
          [theme.breakpoints.down("xs")]: {
            fontSize: "2.5rem",
          },
          [theme.breakpoints.up("lg")]: {
            width: 380,
          },
          [theme.breakpoints.up("xl")]: {
            width: 420,
          },
        },
        "& .services": {
          fontWeight: 600,
          [theme.breakpoints.down("xs")]: {
            fontSize: "1.3rem",
          },
          [theme.breakpoints.up("lg")]: {
            width: 400,
          },
        },
      },
    },
    description: {
      paddingBottom: 5,
      [theme.breakpoints.up("lg")]: {
        width: 400,
      },
    },
    actionContainer: {
      padding: "1rem 1.5rem",
    },
    action: {
      paddingTop: "2rem",

      [theme.breakpoints.up("lg")]: {
        "& button:first-child": {
          marginRight: 40,
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "& button": {
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          margin: "10px 20px 0 0",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: 5,
        },
      },
    },
    orderStatusContainer: {
      paddingTop: "1rem",
      paddingLeft: "8rem",
    },
    preOrder: {
      fontWeight: 600,
      [theme.breakpoints.up("lg")]: {
        width: 400,
      },
    },
  })
);

import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IDeliveryTime } from "../../../Models/deliveryTime";

interface IProps {
  deliveryOpeningTimes: IDeliveryTime[];
  styleClass: string;
}

const DeliveryOpeningTimes: React.FC<IProps> = ({
  deliveryOpeningTimes,
  styleClass,
}) => {
  const { t } = useTranslation();
  const sorter = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  return (
    <TableContainer className={styleClass} component={Paper}>
      <Table>
        <TableBody>
          {deliveryOpeningTimes
            .sort((a, b) => sorter[a.dayOfWeek] - sorter[b.dayOfWeek])
            .map((item) => (
              <TableRow key={item.id}>
                <TableCell>{t(item.dayOfWeek.toLowerCase())}</TableCell>
                {item.id === item.dayOfWeek ? (
                  <TableCell align="right">
                    <span
                      style={{
                        padding: 5,
                        color: "#C00A28",
                        backgroundColor: "#FFF",
                        borderRadius: 5,
                      }}
                    >
                      {t("close")}
                    </span>
                  </TableCell>
                ) : (
                  <TableCell align="right">
                    {item.start}
                    {" - "}
                    {item.end}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryOpeningTimes;

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectInformation } from "../../Stores/informationSlice";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { OffersStyles } from "./OffersStyles";

import { Grid, Paper } from "@material-ui/core";

const Offers = () => {
  const { t } = useTranslation();
  const information = useSelector(selectInformation);
  const classes = OffersStyles();

  return (
    <>
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Grid container justifyContent="center" className={classes.offersRoot}>
        <Grid item xs={12} md={10} component={Paper} className={classes.paper}>
          <h3>{t("specialOffers")}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: information.specialOffersText }}
          ></div>
        </Grid>
      </Grid>
    </>
  );
};

export default Offers;

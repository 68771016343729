import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CheckBookingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("xs")]: {
        position: "relative",
        margin: "1rem auto",
        width: "92%",
      },
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        zIndex: 1,
        top: "2rem",
        padding: "0 2rem 0 1rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "100%",
        padding: "0 1rem",
      },
    },
    paper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "1rem",
    },
    checking: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
    },
    item: {
      display: "flex",
      justifyContent: "center",
    },
    describe: {
      padding: "5px 0 10px",
      [theme.breakpoints.down("xs")]: {
        order: 1,
      },

      "& *": {
        textAlign: "center",
      },
    },
    person: {
      [theme.breakpoints.down("xs")]: {
        order: 2,
      },
    },
    date: {
      [theme.breakpoints.down("xs")]: {
        order: 4,
      },
    },
    time: {
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },
    btn: {
      [theme.breakpoints.down("xs")]: {
        order: 5,
      },
      "& button": {
        width: 138,
      },
    },
    selectFormControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

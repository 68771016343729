import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { dateFormat, selectMoney } from "../../../Stores/informationSlice";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  Paper,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";

import { IPagedCollection } from "../../../Models/pagedCollection";
import { IOrderList } from "../../../Models/order";
import agent from "../../../Services/agent";
import { history } from "../../..";

import { ProfileOrderStyles } from "./ProfileOrderStyles";

const ProfileOrders = () => {
  const { t } = useTranslation();
  const classes = ProfileOrderStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [completeOrders, setCompleteOrders] = useState<
    IPagedCollection<IOrderList>
  >({
    limit: 10,
    offset: 0,
    size: 0,
    value: [],
  });
  const [currentOrders, setCurrentOrders] = useState<
    IPagedCollection<IOrderList>
  >({
    limit: 10,
    offset: 0,
    size: 0,
    value: [],
  });
  const [page, setPage] = React.useState(0);

  const getOrders = useCallback(
    async (limit: number = rowsPerPage, offset: number = 0) => {
      await agent.Order.userOrders(limit, offset).then((res) => {
        setCurrentOrders({
          limit: res.limit,
          offset: res.offset,
          size: res.size,
          value: res.value.filter((i) => i.orderStatusId !== "Complete"),
        });
        setCompleteOrders({
          limit: res.limit,
          offset: res.offset,
          size: res.size,
          value: res.value.filter(
            (i) =>
              i.orderStatusId === "Complete" || i.orderStatusId === "Cancelled"
          ),
        });
      });
    },
    [rowsPerPage]
  );
  const money = useSelector(selectMoney);
  const format = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const dateFormatter = useSelector(dateFormat);

  const handlePage = (limit: number = rowsPerPage, newPage: number = 0) => {
    setPage(newPage);
    getOrders(limit, newPage * limit);
  };

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <Grid container className={classes.ProfileOrder}>
      <Grid item xs={12}>
        <h3 className="profileTitle">{t("orders")}</h3>
      </Grid>
      <Grid item xs={12}>
        <h6>{t("currentOpenOrders")}</h6>
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("ordersDate")}</TableCell>
                <TableCell>{t("orderNumber")}</TableCell>
                <TableCell>{t("orderValue")}</TableCell>
                <TableCell>{t("status")}</TableCell>
                <TableCell>{t("deliveryTracking")}</TableCell>
                <TableCell>{t("details")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentOrders.value.map((item: IOrderList) => (
                <TableRow hover key={item.id}>
                  <TableCell>
                    {dateFormatter.format(new Date(item.createdOn))}
                  </TableCell>
                  <TableCell>{item.customOrderNumber}</TableCell>
                  <TableCell>
                    {money(
                      item.orderTotal - item.discount + item.deliveryCharge
                    )}
                    {/* {money(
                      item.orderTotal - item.discount + item.deliveryCharge
                    )} */}
                  </TableCell>
                  <TableCell>{t(item.orderStatusName)}</TableCell>
                  <TableCell>{t(item.shippingStatusName)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        history.push(`/profile/order-invoice/${item.id}`)
                      }
                      color="primary"
                    >
                      <AssignmentRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePaginationUtil
            count={currentOrders.size}
            getList={getOrders}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[10, 15, 25]}
          /> */}
          <TablePagination
            component="div"
            count={currentOrders.size}
            page={page}
            onPageChange={(e, newPage) => handlePage(rowsPerPage, newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
              // handlePage(parseInt(event.target.value, 10), 0);
            }}
            rowsPerPageOptions={[10, 15, 25]}
            labelRowsPerPage={t("rows")}
            labelDisplayedRows={(paginationInfo) =>
              `${paginationInfo.from} ${t("to")} 
              ${paginationInfo.to} ${t("from")} 
              ${paginationInfo.count} - ${t("page")} ${paginationInfo.page + 1}`}
          />
        </TableContainer>
        {completeOrders.value.length > 0 && (
          <Fragment>
            <h6>{t("recentOrders")}</h6>
            <TableContainer className={classes.table} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("ordersDate")}</TableCell>
                    <TableCell>{t("orderNumber")}</TableCell>
                    <TableCell>{t("orderValue")}</TableCell>
                    <TableCell>{t("status")}</TableCell>
                    <TableCell>{t("deliveryTracking")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completeOrders.value.map((item: IOrderList) => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        {dateFormatter.format(new Date(item.createdOn))}
                      </TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        {money(item.orderTotal - item.discount)}
                      </TableCell>
                      <TableCell>{item.orderStatusName}</TableCell>
                      <TableCell>{item.shippingStatusName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={completeOrders.size}
                page={page}
                onPageChange={(e, newPage) => handlePage(rowsPerPage, newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                  // handlePage(parseInt(event.target.value, 10), 0);
                }}
                rowsPerPageOptions={[10, 15, 25]}
                labelRowsPerPage={t("rows")}
                labelDisplayedRows={(paginationInfo) =>
                  `${paginationInfo.from} ${t("to")} 
                  ${paginationInfo.to} ${t("from")} 
                  ${paginationInfo.count} - ${t("page")} ${paginationInfo.page + 1}`}
              />
            </TableContainer>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
export default ProfileOrders;

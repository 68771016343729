import React, { useEffect, useRef } from "react";
import { AnalogueClockStyles } from "./AnalogueClockStyles";

interface IProps {
  hours: string;
  minutes: string;
}
const AnalogueClock: React.FC<IProps> = ({ hours, minutes }) => {
  const classes = AnalogueClockStyles();
  let min = useRef<HTMLSpanElement>(null);
  let h = useRef<HTMLSpanElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTime = () => {
    if (min && min.current) {
      min.current.style.transform = `rotate(${(+minutes * 6).toString()}deg)`;
    }
    if (h && h.current) {
      h.current.style.transform = `rotate(${(+hours * 30).toString()}deg)`;
    }
  };

  useEffect(() => {
    setTime();
  }, [setTime]);

  return (
    <div className={classes.root}>
      <div className="clock">
        <div className="wrap">
          <h6 className="twelve">12</h6>
          <h6 className="three">3</h6>
          <h6 className="six">6</h6>
          <h6 className="nine">9</h6>
          <span ref={h} className="hour" />
          <span ref={min} className="minute" />
          <span className="dot" />
        </div>
      </div>
    </div>
  );
};

export default AnalogueClock;

import React from "react";
import { Link } from "react-router-dom";
import { IMainProduct } from "../../../../../Models/mainProduct";
import { CategoryChildItem2Styles } from "../../../Category2/CategoryChildItem2/CategoryChildItem2Styles";
// import "../../../../Category/CategoryChildList/CategoryChildItem/CategoryChildItem.scss";
interface IProps {
  mainProduct: IMainProduct;
}
const ProductItem: React.FC<IProps> = ({ mainProduct }) => {
  const classes = CategoryChildItem2Styles();

  return (
    <Link
      to={`/ordering/options/${mainProduct.categoryId}/${mainProduct.id}`}
      className={classes.root}
    >
      <img
        src={`${process.env.REACT_APP_IMG_URL}${mainProduct.picPath}`}
        alt={mainProduct.name}
      />
      <h3>{mainProduct.name}</h3>
    </Link>
  );
};

export default ProductItem;

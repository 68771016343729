import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const CartItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartItem: {
      borderBottom: "1px solid grey",
      margin: "10px 0 5px 0",
      "& .mainItem": {
        display: "flex",
        justifyContent: "space-between",
        "& h6": {
          fontWeight: 600,
          margin: 0,
        },
      },
      "& .currency": {
        float: "right",
        fontWeight: 600,
      },

      "& ul": {
        padding: 0,
        listStyle: "none",
        marginBottom: "0.5rem",
        "& li": {
          width: "100%",
          display: "inline-block",
          "&::before": {
            content: "\u2022",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: "2rem",
            display: "inline-block",
            width: "1rem",
            lineHeight: 0,
            verticalAlign: "middle",
          },
        },
      },

      "& .cartSubtitle": {
        display: "inline-block",
        marginRight: "1rem",
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },

      "& .qty": {
        display: "flex",
        alignItems: "center",
      },

      "& .orderNoteBtn": {
        padding: 6,
        height: 29,
        color: theme.palette.primary.main,
        fontSize: "0.9rem",
      },

      "& button": {
        fontWeight: "600 !important",
      },

      "& .buttons": {
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .green": {
        color: theme.palette.primary.main,
      },
      "& .red": {
        color: theme.palette.secondary.main,
      },
    },
    discountTitle: {
      "& p": {
        fontSize: ".9rem",
        color: theme.palette.secondary.main,
      },
    },
    discount: {
      color: theme.palette.text.primary,
      textDecoration: "line-through",
      textDecorationThickness: "2px",
      textDecorationColor: "#e32222",
    },
    discountPrice: {
      color: "#e32222",
    },
  })
);

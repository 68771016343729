import React from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormControl,
  TextField,
  FormHelperText,
  BaseTextFieldProps,
} from "@material-ui/core";
interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    BaseTextFieldProps {
  required?: true;
  helperText?: string;
}

const ProductTextInput: React.FC<IProps> = ({
  input,
  rows,
  placeholder,
  label,
  multiline,
  meta: { touched, error },
  variant = "outlined",
  margin = "normal",
  autoFocus = false,
  required,
  helperText,
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        error={touched && !!error}
        label={label}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        {...input}
        onChange={(event) => {
          const val = event.target.value;
          if (input.type === "number") input.onChange(parseFloat(val));
          else input.onChange(val);
        }}
        variant={variant as any}
        margin={margin}
        autoFocus={autoFocus}
        size="small"
        required={required}
        helperText={helperText}
      />
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ProductTextInput;

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const ProfileMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileMenu: {
      "& svg": {
        marginRight: "0.5rem",
      },

      "& ul": {
        listStyle: "none",
        padding: 0,
        margin: "0 auto",
      },

      "& a": {
        display: "flex",
        textDecoration: "none",
        color: "#0c2936",

        "& &:hover": {
          opacity: 0.8,
        },
      },

      "& a.isActive": {
        color: theme.palette.primary.main,

        "& svg": {
          stroke: theme.palette.primary.main,
        },
      },
      "& .profileMenu-title": {
        textAlign: "center",
        fontWeight: 600,
        marginBottom: "1rem",
        paddingBottom: "1rem",
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        color: "#0c2936",
      },

      "& .profileMenu-item": {
        marginBottom: "0.7rem",
        paddingLeft: "1.5rem",
        "& svg": {
          stroke: "#0c2936",
        },
      },
    },
  })
);

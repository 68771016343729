import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const ParkingInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    parkingInfo: {
      flexGrow: 1,
      padding: 10,

      "& .gridItem": {
        padding: 5
      },

      "& .paper": {
        padding: "1rem",
        height: "100%",
        backgroundColor: theme.palette.background.paper
      },

      "& .map": {
        minHeight: 400,
        height: "100%",
        padding: "1rem"
      },

      "& .ticketForm": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",

        "& >*": {
          margin: "10px 0",
        },
      }
    }
  })
);

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const Radio1Styles = makeStyles((theme: Theme) =>
  createStyles({
    ProductSizeStyle: {
      /* The container */
      "&.radioButton": {
        display: "flex",
        alignItems: "center",
        position: "relative",
        paddingLeft: 30,
        margin: "0 10px 10px 0",
        cursor: "pointer",
        fontSize: 19,
        lineHeight: "30px",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
      },
      /* Hide the browser's default radio button */
      "& input": {
        position: "absolute",
        opacity: 0,
        cursor: "pointer",
      },
      /* Create a custom radio button */
      "& .checkmark": {
        boxSizing: "content-box",
        position: "absolute",
        top: 3,
        left: 0,
        height: 22,
        width: 22,
        borderRadius: "50%",
        border: "2px solid #757575",
      },
      /* On mouse-over, add background color */
      "&.radioButton:hover input ~ .checkmark": {
        backgroundColor: "#eee",
      },
      /* When the radio button is checked, add background color */
      "&.radioButton input:checked ~ .checkmark": {
        backgroundColor: "#fff",
        borderColor: theme.palette.primary.main,
      },
      /* Styling indicator (circle) */
      "& .checkCircle": {
        position: "absolute",
        display: "none",
        top: 10,
        left: 7,
        width: 12,
        height: 12,
        borderRadius: "50%",
        background: theme.palette.primary.main,
        zIndex: 99,
      },

      /* Show the indicator (circle) when checked */
      "&.radioButton input:checked ~ .checkCircle": {
        display: "block",
      },
    },
  })
);

import React from "react";
import { useSelector } from "react-redux";

import { ISoloProduct } from "../../../../Models/product";
import { selectMoney } from "../../../../Stores/informationSlice";

interface IProps {
  product: ISoloProduct;
  checked: boolean;
  mainProductName: string;
  handleRadioButton: (id: string) => void;
}

const RadioProduct2: React.FC<IProps> = ({
  product,
  checked,
  mainProductName = "Main",
  handleRadioButton,
}) => {
  const money = useSelector(selectMoney);

  return (
    <label>
      {`${product.name} ${
        product.price > 0 ? `(${money(product.price)})` : ""
      }`}
      <input
        onClick={() => handleRadioButton(product.id)}
        type="radio"
        value={product.id}
        name={mainProductName}
        defaultChecked={checked}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default RadioProduct2;

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CategoryChildItem2Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      transitionDuration: "0.3s",

      "@media (max-width: 767px)": {
        width: 120,
        padding: 0,
      },

      "@media (min-width: 768px)": {
        width: 140,
        padding: 0,
      },

      "@media (min-width: 960px)": {
        width: 200,
        padding: "0.5rem",
      },

      "&:hover": {
        transform: "scale(1.05)",
      },

      "& img": {
        display: "block",
        margin: "0 auto",
        objectFit: "contain",

        "@media (max-width: 767px)": {
          width: 115,
          height: 115,
        },

        "@media (min-width: 768px)": {
          width: 120,
          height: 120,
        },

        "@media (min-width: 960px)": {
          width: 160,
          height: 160,
        },
      },

      "& h3": {
        color: theme.palette.text.primary,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: 600,
        margin: "0.5rem 0.2rem",
        background: `${theme.palette.background.default}80`,
        borderRadius: 5,
      },

      "& h4": {
        color: "#438a00",
        textAlign: "center",
        fontSize: "1.3rem",
        margin: 0,
      },

      "& p": {
        color: "#575757",
        width: "100%",
        textAlign: "center",
      },
    },
  })
);

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const FreebiesItemRedisStyles = makeStyles((theme: Theme) =>
  createStyles({
    discountProduct: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 10px 3px 10px",
      borderBottom: `2px solid #000`,
      // borderBottom: `2px solid ${theme.palette.text.primary}`,
      transitionDuration: "0.3s",
      color: "#000",

      "@media only screen and (max-width: 600px)": {
        width: "90% !important",
      },

      "@media only screen and (min-width: 600px)": {
        marginBottom: 10,
      },

      "@media only screen and (max-width: 960px)": {
        padding: 0,
      },

      "&:hover": {
        color: "#000",
      },
      "& .naming": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        [theme.breakpoints.down("xs")]: {
          marginTop: 5,
        },
        "& .name": {
          color: "#000",
          // color: theme.palette.text.primary,
          fontWeight: 600,
          fontSize: "1rem",

          [theme.breakpoints.up("sm")]: {
            fontSize: "1.2rem",
          },

          [theme.breakpoints.up("md")]: {
            fontSize: "1.1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        "& .desc": {
          display: "flex",
          width: "100%",
          color: "#000",
          // color: theme.palette.text.primary,

          [theme.breakpoints.up("sm")]: {
            marginLeft: 10,
          },

          "& .contentStyle": {
            width: "100%",
          },
          "& .anchorStyle": {
            float: "right",
          },

          "& *": {
            margin: 0,
            padding: 0,
            lineHeight: "24px",
          },

          "& button": {
            width: 30,
            height: 30,
            [theme.breakpoints.up("sm")]: {
              marginLeft: "5px !important",
              padding: 5,
            },
            [theme.breakpoints.down("sm")]: {
              margin: 0,
              padding: "3px 10px",
              fontSize: 10,
            },
          },
        },
      },

      "& .ordering": {
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        textAlign: "center",
        marginBottom: 5,
        fontSize: "1.1rem",
        fontWeight: 600,
        paddingTop: 5,

        [theme.breakpoints.up("md")]: {
          height: "100%",
        },
        [theme.breakpoints.down("md")]: {
          justifyContent: "space-between",
        },

        "& .price": {
          color: "#000",
          // color: theme.palette.text.primary,

          [theme.breakpoints.up("md")]: {
            marginTop: 7,
          },
        },

        "& .whiteBg": {
          position: "absolute",
          width: 15,
          height: 15,
          backgroundColor: "#fff",
        },
        "& svg": {
          zIndex: 1,
        },
      },
    },
    addToCartBtn: {
      marginLeft: 5,
      color: "#d0021b",
      // color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        padding: 3,
      },
    },
  })
);

import { Grid, Paper } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { _404PageStyles } from "./_404PageStyles";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import AvTimerRoundedIcon from "@material-ui/icons/AvTimerRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { history } from "../..";
import { useSelector } from "react-redux";
import { selectInformation } from "../../Stores/informationSlice";
import { useTranslation } from "react-i18next";

const _404Page = () => {
  const { t } = useTranslation();
  const classes = _404PageStyles();
  const information = useSelector(selectInformation);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Paper className={classes.paper}>
        <h2>{t("pageNotFind")}</h2>
        <p>{t("weCouldntFindThePage")}</p>
        <p>{t("youMayWantToExplore")}</p>
        <div className={classes.links}>
          <NavLink
            onClick={() =>
              localStorage.getItem("lastPath") === "ordering"
                ? (history.go(-2), localStorage.removeItem("lastPath"))
                : history.go(-1)
            }
            to="#"
          >
            <ArrowBackRoundedIcon fontSize="large" color="secondary" />
            <h6>{t("back")}</h6>
          </NavLink>
          <NavLink to="/">
            <HomeRoundedIcon fontSize="large" color="secondary" />
            <h6>{t("homePage")}</h6>
          </NavLink>
          <NavLink to="/ordering/department">
            <LocalMallRoundedIcon fontSize="large" color="secondary" />
            <h6>{t("orderNow")}</h6>
          </NavLink>
          <NavLink to="opening-time">
            <AvTimerRoundedIcon fontSize="large" color="secondary" />
            <h6>{t("openingTimes")}</h6>
          </NavLink>
        </div>
      </Paper>
    </Grid>
  );
};

export default _404Page;
